import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { connectSocket } from '../../../../shared/sockets/socket';
import { useFormikContext } from 'formik';
import DOMPurify from 'dompurify';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ editor, children }) => {
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const [ws, setWs] = useState(null);

  const formikProps = useFormikContext();

  const [openOutlineRewrite, setOpenOutlineRewrite] = useState(false);
  
  const introHeading = useRef("");
  const [openIntroductionModal, setOpenIntroductionModal] = useState(false);
  const [openIntroductionRewrite, setOpenIntroductionRewrite] = useState(false);

  const [partData, setPartData] = useState(null);
  const [openPartModal, setOpenPartModal] = useState(false);
  const [openPartRewrite, setOpenPartRewrite] = useState(false);

  const concluHeading = useRef("");
  const [openConclusionModal, setOpenConclusionModal] = useState(false);
  const [openConclusionRewrite, setOpenConclusionRewrite] = useState(false);

    // useEffect(() => { if (openIntroductionRewrite === true) {
    //     setOpenIntroductionRewrite(true)
    // } }, [openIntroductionRewrite])

    // useEffect(() => { if (openIntroductionModal === true) { 
    //     console.log("CLOSES REWRITE")
    //     setOpenIntroductionRewrite(false)
    // } }, [openIntroductionModal])

  const startPos = useRef(null); // Track start position for insertion
  const chunkPos = useRef(null); // Track aggregated content position for insertion
  const endPos = useRef(null); // Track end position to grab content

  const bufferRef = useRef(''); // Buffer to accumulate chunks

  const cleanGeneratedContent = (content) => {
    const cleanedContent = content.replace(/>\s*\n\s*</g, '><');
    let sanitizedHtml = DOMPurify.sanitize(cleanedContent, { USE_PROFILES: { html: true } });
    // Handle lists with empty content or empty list element
    sanitizedHtml = sanitizedHtml.replace(/<ul>\s*<\/ul>/g, '<ul><li> </li></ul>');
    sanitizedHtml = sanitizedHtml.replace(/<ol>\s*<\/ol>/g, '<ol><li> </li></ol>');
    sanitizedHtml = sanitizedHtml.replace(/<li>(\s*<[^>]+>\s*<\/[^>]+>|\s*)<\/li>/g, '<li> </li>');
    sanitizedHtml = sanitizedHtml.replace(/<blockquote>(\s*<[^>]+>\s*<\/[^>]+>|\s*)<\/blockquote>/g, '<blockquote> </blockquote>');
    return sanitizedHtml;
  }

    useEffect(() => {
        if (userInfo && !ws) {
            const socket = connectSocket(userInfo.unique_id); // Connect to WebSocket
            setWs(socket);
            console.log("SOCKET CONNECTED");

            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                bufferRef.current = bufferRef.current + data.data

                if (data.contentType === "outline") {
                    if (data.isRewrite) {
                        setOpenOutlineRewrite(value=>true);
                        formikProps.setFieldValue('outline_rewrite', bufferRef.current);
                    } else {
                        formikProps.setFieldValue('outline', bufferRef.current);
                        const zone = document.getElementById('introzone');
                    }
                } else if (data.contentType === "introduction") {
                    if (data.isRewrite) {
                        setOpenIntroductionRewrite(value=>true);
                        formikProps.setFieldValue('introduction_rewrite', bufferRef.current);
                    } else {
                        setOpenIntroductionModal(value=>false);
                        const zone = document.getElementById('introzone');
                        if (zone) {
                          zone.innerHTML = introHeading.current + bufferRef.current;
                        }
                    }
                } else if (data.contentType === "part") {
                    if (data.isRewrite) {
                        setOpenPartRewrite(value=>true);
                        setPartData(currentPartData => {
                            formikProps.setFieldValue('content_rewrite', {
                              ...formikProps.values.content_rewrite,
                              [currentPartData?.title]: cleanGeneratedContent(bufferRef.current),
                            });
                            return currentPartData;
                        });
                    } else {
                        setOpenPartModal(value=>false);
                        setPartData(currentPartData => {
                            editor
                            .chain()
                            .focus()
                            .deleteRange({ from: currentPartData.startOfPart, to: currentPartData.endOfPart })
                            .insertContentAt(currentPartData.startOfPart, cleanGeneratedContent(bufferRef.current))
                            .run();

                            const updatedPartData = {
                                ...currentPartData,
                                endOfPart: editor.state.selection.to,
                            };
                            return updatedPartData;
                        });
                    }
                } else if (data.contentType === "conclusion") {
                    if (data.isRewrite) {
                        setOpenConclusionRewrite(value=>true);
                        formikProps.setFieldValue('conclusion_rewrite', bufferRef.current);
                    } else {
                        setOpenConclusionModal(value=>false);
                        const zone = document.getElementById('conclusionzone');
                        if (zone) {
                          zone.innerHTML = concluHeading.current + bufferRef.current;
                        }
                    }
                } else if (data.contentType === "custom"){
                    // Append new chunk to buffer and insert into editor
                    if (!data.isRewrite) {
                        // Open modal and render in modal
                        console.log("RECEIVED CUSTOM")
                    }
                }
            };
        }
    }, [userInfo, ws]);

  return (
    <WebSocketContext.Provider value={{ 
        ws,
        openOutlineRewrite,
        setOpenOutlineRewrite,
        openIntroductionModal,
        setOpenIntroductionModal,
        openIntroductionRewrite,
        setOpenIntroductionRewrite,
        partData,
        setPartData,
        openPartModal,
        setOpenPartModal,
        openPartRewrite,
        setOpenPartRewrite,
        openConclusionModal,
        setOpenConclusionModal,
        openConclusionRewrite,
        setOpenConclusionRewrite,
        introHeading,
        concluHeading,
        bufferRef,
        cleanGeneratedContent
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook for accessing WebSocket context
export const useWebSocket = () => useContext(WebSocketContext);
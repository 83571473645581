import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './app/redux/store';
import router from './app/router/router';
import { ClipLoader } from 'react-spinners';
import './scss/styles.scss';
import './i18n';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<ClipLoader size={25} color="white" />}>
          <RouterProvider router={router} />
        </React.Suspense>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

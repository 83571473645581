import { useTranslation } from 'react-i18next';
import { Check, Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Grid } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setIsPanelOpen } from '../../../../../redux/slices/panelSlice';
import { dispatch } from '../../../../../redux/store';
import { useFormikContext } from 'formik';
import GlobalNumberField from '../FeaturesTemplate/FeaturesFields/GlobalNumberField';
import { globalValidationConfig } from './formik-function/helpers';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useGeneratePostH1TagMutation } from '../../../../../redux/slices/featuresApiSlice';
import { BsMagic } from 'react-icons/bs';
import { MdOutlineQueryStats } from 'react-icons/md';
import { LaunchAnalysisBtn } from '../Others';

const H1Title = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);

  const [generatePostH1Tag, { isLoading }] = useGeneratePostH1TagMutation();
  const formikProps = useFormikContext();
  const seoInput =
    formikProps.values.seo_request === ''
      ? 'REQUEST'
      : formikProps.values.seo_request;
  const [open, setOpen] = useState(false);

  const isPanelOpen = useSelector((state) => state.panelSlice.isPanelOpen);

  const handleOpen = () => {
    if (!isPanelOpen) {
      setOpen(true);
      dispatch(setIsPanelOpen(true));
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setIsPanelOpen(false));
    document.getElementById('h1zone').innerHTML = formikProps.values.h1_title;
  };

  const handleH1Change = (value) => {
    formikProps.setFieldValue('h1_title', value);
    document.getElementById('h1zone').innerHTML = value;
  };

  const handleGeneration = async () => {
    try {
      const payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        nb_outputs: formikProps.values.nb_h1_title,
        outputs: formikProps.values.h1_title_ideas,
      };
      if (formikProps.values.h1_title !== '') {
        payload['h1_tag'] = formikProps.values.h1_title;
      }
      console.log('h1 title generation payload: ', payload);
      const res = await generatePostH1Tag(payload).unwrap();

      // const res = {
      //   status: 200,
      //   data: [
      //     "<h1>Découvrir la faune sauvage en France : Pourquoi c'est essentiel ?</h1>",
      //     '<h1>Observer la nature : Bienfaits et responsabilités</h1>',
      //     "<h1>Les meilleurs lieux pour l'observation des animaux sauvages en France</h1>",
      //   ],
      // };

      if (res?.status !== 200) {
        toast.error('Error occurred while generating H1 Tag');
        return;
      }
      //const res = resData.h1_title;
      console.log(res?.data);
      //const newData = res?.data?.map((str) => str.replace(/<\/?h1>/g, ''));
      formikProps.setFieldValue('h1_title_ideas', res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: 'fixed',
            overflow: { md: 'unset', xs: 'scroll' },
            height: { md: '100vh', xs: '100%' },
            width: '100%',
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            mt: { md: '48px !important', xs: 'unset' },
            left: 0,
            top: 0,
            bgcolor: '#000000a1',
          }}
        >
          <Box
            sx={{
              width: { md: 'calc(100vw - 350px)', xs: '100%' },
              display: 'flex',
              justifyContent: 'center',
              height: 'auto',
              overflow: { md: 'auto', xs: 'unset' },
            }}
          >
            <Box sx={{ width: '100%', my: { md: 'unset', xs: '40px' } }}>
              <div
                className="mx-[28px] py-[5px] px-[10px] rounded-[0.4rem] mt-[124px] bg-white border-[2px]
               border-slate-200 text-[1.7em] font-semibold"
                dangerouslySetInnerHTML={{
                  __html:
                    formikProps.values.h1_title === ''
                      ? t('Your title here ... ', 'Votre titre ici ...')
                      : formikProps.values.h1_title,
                }}
              >
                {/* {formikProps.values.h1_title === "" ? (
                  <h1 className="text-slate-400">Your title here ...</h1>
                ) : (
                  <h1>{formikProps.values.h1_title}</h1>
                )} */}

                {/* <input
                  className=" border-none focus:outline-none"
                  placeholder="Your H1 Title here"
                  //value={formikProps.values.h1_title}
                  value={"hello"}
                  readOnly
                /> */}
              </div>
              <Box
                sx={{
                  mx: 'auto',
                  mt: 4,
                  bgcolor: 'white',
                  maxWidth: '900px',
                  width: '95%',
                  borderRadius: 3,
                }}
              >
                {/* <Box sx={{ overflowX: 'scroll', scrollbarWidth: 'thin' }}> */}
                {/* <Box sx={{ minWidth: '450px' }}> */}
                <Box
                  sx={{
                    py: 2,
                    px: 3,
                    position: 'relative',
                    borderBottom: '2px solid #e5e7eb',
                  }}
                >
                  <p className="text-2xl font-semibold">
                    {t('H1-tag', 'Titre (Balise H1)')}
                  </p>
                  <Box
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      cursor: 'pointer',
                      right: 0,
                      top: 0,
                      p: 2,
                    }}
                  >
                    <Close sx={{ color: 'black', fontSize: 32 }} />
                  </Box>
                </Box>

                <Box
                  sx={{
                    bgcolor: 'white',
                    px: 3,
                    py: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <div className="flex">
                        <LoadingButton
                          startIcon={<BsMagic />}
                          loading={isLoading}
                          onClick={handleGeneration}
                          variant="contained"
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            mt: 2,
                            bgcolor: '#8b5cf6',
                          }}
                        >
                          {t(
                            'Generate-ideas-for-h1-title',
                            'Générer des idées de balise H1'
                          )}
                        </LoadingButton>

                        <GlobalNumberField
                          style={{
                            bgcolor: 'white',
                            borderRadius: 'none !important',
                          }}
                          size="small"
                          formikProps={formikProps}
                          name={'nb_h1_title'}
                          isRequired={
                            globalValidationConfig.nb_h1_title.required
                          }
                          minValue={globalValidationConfig.nb_h1_title.min}
                          maxValue={globalValidationConfig.nb_h1_title.max}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <div className="flex flex-col max-h-[275px] overflow-auto mt-2 pr-2 gap-4">
                        {formikProps?.values?.h1_title_ideas?.map(
                          (item, index) => (
                            <div className="flex flex-col">
                              <div className="ml-auto">
                                <Check
                                  className="cursor-pointer"
                                  onClick={() =>
                                    formikProps.setFieldValue('h1_title', item)
                                  }
                                />

                                <Close
                                  className="cursor-pointer"
                                  onClick={() =>
                                    formikProps.setFieldValue(
                                      'h1_title_ideas',
                                      formikProps.values.h1_title_ideas.filter(
                                        (i) => i !== item
                                      )
                                    )
                                  }
                                />
                              </div>
                              <div
                                dangerouslySetInnerHTML={{ __html: item }}
                                className="bg-neutral-200 font-semibold p-4 rounded-md"
                              />
                            </div>
                          )
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                {/* </Box> */}
                {/* </Box> */}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: '#303539',
              height: '100vh',
              width: { md: '350px', xs: '100%' },
              color: 'white',
              pb: 5,
            }}
          >
            <Box
              sx={{
                backgroundColor: '#eab308',
                p: 2,
                position: 'relative',
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: 0,
                  top: 0,
                  p: 2,
                }}
              >
                <Close sx={{ color: 'white', fontSize: 32 }} />
              </Box>
              <h1 className="text-2xl mb-2">
                {t('H1-tag', 'Titre (Balise H1)')}
              </h1>

              <p className="text-xs mb-2">
                {t(
                  'tutorial-text-h1-tag',
                  'La balise H1 est un important facteur du référencement de votre page, autant par l’importance qu’elle a pour Google que pour vos lecteurs. Suivez nos indications, générez des idées et inspirez-vous de la la concurrence !'
                )}
              </p>

              <p className="text-xs">
                {t('For-more-info:', "Pour plus d'infos :")}{' '}
                <span>
                  <a className="text-blue-500 underline cursor-pointer">
                    {
                      ('h1-tag-optimization-guide',
                      'Balise H1 optimisée pour le SEO : le Guide Complet 2024')
                    }
                  </a>
                </span>
              </p>
            </Box>

            <LaunchAnalysisBtn
              userInfo={userInfo}
              formikProps={formikProps}
              disabled={true}
            />
          </Box>
        </Box>
      ) : (
        <div onClick={handleOpen} className="panel-button">
          {t('H1-tag', 'Titre (Balise H1)')}
        </div>
      )}
    </>
  );
};

export default H1Title;

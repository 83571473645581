import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import { useGetGuideInfoQuery, useUpdateGuideInfoMutation } from '../../redux/slices/dashboardApiSlice';
import { toast } from "react-toastify";
import { GetStartedGuide, CommunityPanel, NotificationPanel, QuickAccessPanel, ReferralPanel, UsagePanel } from './index'
import { H1Header } from '../../shared/components/others';
import CloseIcon from "@mui/icons-material/Close";
import { CustomLoader } from '../../shared/components/others'
import { Box } from "@mui/material";
import { useSelector } from 'react-redux';

export default function Dashboard() {
  const { t } = useTranslation();
  const { data: guideInfo, isLoading: guideInfoLoading, refetch } = useGetGuideInfoQuery();
  const [updateGuideInfo] = useUpdateGuideInfoMutation();
  const [isGuideCompleted, setIsGuideCompleted] = useState(false);

  if (guideInfoLoading) {
    return <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"65vh"}>
      <CustomLoader />
    </Box>;
  }

  const handleClose = async (isCompleted = false) => {
    try {
      const payload = {
        "show_guide": false,
        "guide_step": guideInfo?.data?.guide_step
      };
      await updateGuideInfo(payload);
      if (isCompleted) {
        setIsGuideCompleted(true);
      }
    } catch (error) {
        toast.error(error?.data?.detail || t("something-went-wrong"));
    }
  };

  return (
    <div className='px-2 pl-6 pr-6'>

      <div className="py-3">
        <H1Header title={t("Dashboard","Tableau de bord")} />
      </div>

      <div className="grid xl:grid-cols-5 md:gap-5 gap-y-5">

        {isGuideCompleted && 
          <div className="flex items-center justify-between bg-white rounded-lg py-2 px-3 xl:col-span-5 col-span-3 shadow-default">
          <p className="font-semibold text-black text-lg">
            {t(
              "finished-guide-congrats",
              "Bravo d'avoir fini le guide ! Si vous souhaitez le re consulter, rendez vous dans"
            )}{" "}
            {t("docs", "Documentation")} / {t("get-started", "Démarrer")}
          </p>
          <button
            type="button"
            className="rounded-md bg-white focus:outline-none"
            onClick={() => setIsGuideCompleted(false)}
          >
            <span className="sr-only">Close</span>
            <CloseIcon style={{ height: "25px", width: "25px" }} />
          </button>
          </div>
        }

        {guideInfo.data.show_guide && 
          <GetStartedGuide
            guideInfo={guideInfo.data}
            updateGuideInfo={updateGuideInfo}
            handleClose={handleClose}
            refetch={refetch}
          />
        }
        
        <div className="col-span-3 ">
          <div className="bg-white rounded-lg px-5 py-4 pb-5 sm:px-7.5 shadow-default">
            <QuickAccessPanel />
          </div>
          <UsagePanel />
        </div>
        
        <div className="xl:col-span-2 col-span-3">
          <NotificationPanel />
          <CommunityPanel />
          <ReferralPanel />
        </div>
      </div>
    </div>
  );
}

// {isGuideCompleted && 
//   <Box style={style} mb={2}>
//     <Typography fontSize={{ lg: 20, md: 20, sm: 20, xs: 16 }} fontWeight={600}>
//       {t("finished-guide-congrats", "Bravo d'avoir fini le guide ! Si vous souhaitez le re consulter, rendez vous dans")} {t("docs", "Documentation")} / {t("get-started", "Démarrer")}
//     </Typography>
//   </Box>
// }

// {guideInfo.data.show_guide && 
//   <GetStartedGuide
//     guideInfo={guideInfo.data}
//     updateGuideInfo={updateGuideInfo}
//     handleClose={handleClose}
//     refetch={refetch}
//   />
// }
import { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { refreshAccessTokenSuccess, logout } from '../../../redux/slices/authSlice';
import { jwtDecode } from "jwt-decode";
import { useRefreshTokenMutation } from '../../../redux/slices/authApiSlice';
import { CustomLoader } from '../../components/others';
import handleLogout from '../../../redux/logout';

const PrivateRoute = () => {
  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const accessToken = useSelector((state) => state?.auth?.accessToken);
  const dispatch = useDispatch();
  const [refreshToken, { isLoading }] = useRefreshTokenMutation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isTokenExpired = (token) => {
      if (!token) return true;

      try {
        const decodedToken = jwtDecode(token);
        const expirationBuffer = 10;
        const currentTime = Math.floor(Date.now() / 1000);
        return decodedToken.exp - currentTime < expirationBuffer;
      } catch (error) {
        return false;
      }
    };

    const refreshAccessToken = () => async () => {
      try {
        const res = await refreshToken().unwrap();
        console.log("ROUTE result")
        console.log(res);

        if (res.status === 200) {
          dispatch(refreshAccessTokenSuccess({ accessToken: res.access_token }));
          console.log("ROUTE : Token has been refreshed well!")
        }
        
      } catch (error) {
        console.log("ROUTE : Token refresh failed!")
        handleLogout(dispatch);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExpired(accessToken)) {
      console.log("ROUTE : Token needs to be refreshed")
      dispatch(refreshAccessToken());
    } else {
      console.log("ROUTE : Token does not need to be refreshed")
      setLoading(false);
    }
  }, [accessToken, dispatch, refreshToken]);

  const notify = () => {
    toast.error('You need to be logged in to access this page.');
  };

  if (loading || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CustomLoader />
      </div>
    );
  }

  return <>{(accessToken && userInfo) ? <Outlet /> : <>{notify()} <Navigate to="/login" replace /></>}</>;
};
export default PrivateRoute;

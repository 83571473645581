import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import { BsMagic } from 'react-icons/bs';
import { useGeneratePostPartMutation } from '../../../../../../redux/slices/featuresApiSlice';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import RewriteModal from '../../SidePanel/global-rewrite-modal/rewrite-modal';
import { useWebSocket } from '../../WebSocketContext';
import { toast } from 'react-toastify';

const PartModal = ({ editor, setOpen, partData }) => {
  const { t } = useTranslation();

  const [generatePostPart, { isLoading }] = useGeneratePostPartMutation();
  const {openPartRewrite, setOpenPartRewrite, setOpenPartModal, bufferRef, cleanGeneratedContent} = useWebSocket();
  const formikProps = useFormikContext();

  const handleGenerate = async () => {
    const el = document.createElement('div');
    el.innerHTML = partData.content;
    const result = cleanUpElementToJSON(el);

    const isPartGenerated = partData.content.includes("<p>");

    try {
      const payload = {
        style_id: formikProps.values.style_id === null || formikProps.values.style_id === "" ? null : formikProps.values.style_id,
        company_id: formikProps.values.company_id === null || formikProps.values.company_id === "" ? null : formikProps.values.company_id,
        product_id: formikProps.values.product_id === null || formikProps.values.product_id === "" ? null : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        part_name: result.part_name,
      };
      if (isPartGenerated) {
        payload['part'] = result.part;
      }

      console.log('part generation payload: ', payload);

      const res = await generatePostPart({...payload, ...{ stream: true }}).unwrap();

      if (res?.status !== 200) {
        toast.error('Error ocurred while generating part');
        return;
      }

      console.log('part generation res: ', res);

      const contentRewrite = formikProps.values.content_rewrite;

      if (isPartGenerated) {
        formikProps.setFieldValue('content_rewrite', {
          ...contentRewrite,
          [partData.title]: cleanGeneratedContent(res?.data),
        });
      } else {
        editor
          .chain()
          .focus()
          .deleteRange({ from: partData.startOfPart, to: editor.state.selection.to }) // !!! IN YOUR CASE HERE YOU WILL SET "to" TO endPos, AS I TOLD YOU IN VIDEO I JUST DO THAT DIFFERENT THING WHEN I USE CHUNKS
          .insertContentAt(partData.startOfPart, cleanGeneratedContent(res?.data))
          .run();
      }

      bufferRef.current = "";
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else {
        toast.error(t("something-went-wrong", "Une erreur s'est produite"))
      }
      console.log(error);
    }
  };

  const saveSelectedVersion = (data) => {
    editor
      .chain()
      .focus()
      .deleteRange({ from: partData.startOfPart, to: partData.endOfPart }) // !!! IN YOUR CASE HERE YOU WILL SET "to" TO endPos, AS I TOLD YOU IN VIDEO I JUST DO THAT DIFFERENT THING WHEN I USE CHUNKS
      .insertContentAt(partData.startOfPart, data)
      .run();
    const contentRewrite = { ...formikProps.values.content_rewrite };
    delete contentRewrite[partData.title];
    formikProps.setFieldValue('content_rewrite', contentRewrite);
    setOpenPartRewrite(false);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        //overflow: { md: 'unset', xs: 'scroll' },
        height: '100vh',
        width: { md: 'calc(100vw - 350px)', xs: '100%' },
        display: 'flex',
        mt: { md: '48px !important', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 99999,
      }}
    >
        <div className="m-auto bg-white rounded-xl w-[80%] max-w-[500px]">
          <div className="flex justify-between p-4 items-center border-b-[3px] border-slate-200">
            <p>{partData.content.includes("<p>") ? t('(Re)-Generate-part', "(Re) Générer la partie") : t('Generate-part', "Générer la partie")}</p>
            <Close
              onClick={() => setOpenPartModal(false)}
              className="cursor-pointer"
              sx={{ fontSize: 32 }}
            />
          </div>
          <div className="p-4">
            <p>{t("Selected-part", "Partie sélectionnée")} :</p>
            <p className="font-semibold mb-2">{`(H${partData.level}) ${partData.titleText}`}</p>
            <div className="flex items-center gap-4">
              <LoadingButton
                startIcon={<BsMagic />}
                loading={isLoading}
                onClick={handleGenerate}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  bgcolor: '#8b5cf6',
                }}
              >
                {t("Generate-the-part", "Générer la partie")}
              </LoadingButton>

              {formikProps.values.content_rewrite[partData.title] ? (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                  }}
                  onClick={() => setOpenPartRewrite(true)}
                >
                  View Rewritten version
                </Button>
              ) : null}

              {openPartRewrite ? (
                <div
                  style={{
                    width: 'calc(100vw - 350px)',
                  }}
                  className="fixed z-[99999] mt-[48px] top-0 bg-[#00000029] left-0 flex   h-screen"
                >
                  <RewriteModal
                    previous={partData.content}
                    rewritten={formikProps.values.content_rewrite[partData.title]}
                    save={saveSelectedVersion}
                    setOpenModal={setOpenPartRewrite}
                    label="Part Rewrite"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
    </Box>
  );
};

function cleanUpElementToJSON(rootElement) {
  if (!rootElement) return { part_name: '', part: '' };

  // Helper function to remove <p> and <br> tags
  function cleanPartName(element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.querySelectorAll('p, br').forEach((el) => el.remove());
    return clonedElement.innerHTML;
  }

  // Helper function to remove only empty <p> tags
  function cleanPart(element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.querySelectorAll('p').forEach((el) => {
      if (!el.textContent.trim()) el.remove();
    });
    return clonedElement.innerHTML;
  }

  // Generate the part_name and part fields
  const part_name = cleanPartName(rootElement);
  const part = cleanPart(rootElement);

  // Return the result as a JSON object
  return {
    part_name: part_name,
    part: part,
  };
}

export default PartModal;
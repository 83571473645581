import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { Close } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { dispatch } from '../../../../../redux/store';
import { setIsPanelOpen } from '../../../../../redux/slices/panelSlice';
import { useSelector } from 'react-redux';
import {LaunchAnalysisBtn} from "../Others";

const Linking = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);

  const formikProps = useFormikContext();

  const [open, setOpen] = useState(false);
  const isPanelOpen = useSelector((state) => state.panelSlice.isPanelOpen);

  const handleOpen = () => {
    if (!isPanelOpen) {
      setOpen(true);
      dispatch(setIsPanelOpen(true));
    }
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(setIsPanelOpen(false));
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: 'fixed',
            overflow: { md: 'unset', xs: 'scroll' },
            height: { md: '100vh', xs: '100%' },
            width: { md: 'auto', xs: '100%' },
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'end',
            mt: { md: '48px !important', xs: 'unset !important' },
            right: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: '#303539',
              height: '100vh',
              width: { md: '350px', xs: '100%' },
              color: 'white',
              pb: 5,
            }}
          >
            <Box
              sx={{
                backgroundColor: '#3e4756',
                p: 2,
                position: 'relative',
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: 0,
                  top: 0,
                  p: 2,
                }}
              >
                <Close sx={{ color: 'white' }} />
              </Box>
              <h1 className="text-2xl mb-2">{t("Interna-and-external-linking", "Maillage (interne et externe)")}</h1>

              <p className="text-xs mb-2">{t("tutorial-text-linking","Le maillage (ajout de liens au contenu) est un aspect essentiel du SEO : il ajoute des informations utiles, organise le contenu et tisse des relations entre les pages du web. Suivez nos conseils pour optimiser votre maillage !")}</p>

              <p className="text-xs">
                {t("For-more-info:", "Pour plus d'infos :")}{" "}
                <span>
                  <a className="text-blue-500 underline cursor-pointer">
                    {("linking-optimization-guide", "Créer un bon maillage interne et externe en SEO : le Guide Complet 2024")}
                  </a>
                </span>
              </p>
            </Box>
            
            <LaunchAnalysisBtn userInfo={userInfo} formikProps={formikProps} disabled={true} />
          </Box>
        </Box>
      ) : (
        <div onClick={handleOpen} className='panel-button'>
          {t("Interna-and-external-linking", "Maillage (interne et externe)")}
        </div>
      )}
    </>
  );
};

export default Linking;

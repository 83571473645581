import { useTranslation } from 'react-i18next';
import { BubbleMenu } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { DOMSerializer } from '@tiptap/pm/model';
import PartModal from './part-modal';
import { SlNote } from 'react-icons/sl';
import { useWebSocket } from '../../WebSocketContext';

const PartBubbleMenu = ({ editor }) => {
  const { t } = useTranslation();

  const {
    openPartModal,
    setOpenPartModal,
    partData,
    setPartData
  } = useWebSocket()

  let partGenerated;

  const isContentZone = (node) => {
    if (!node || node?.name === 'doc') return false;
    if (node?.name === 'contentzone') {
      return true;
    }
    return isContentZone(node.parent);
  };

  const getEndOfContentZone = (node) => {
    if (!node || node?.name === 'doc') return null;
    if (node?.name === 'contentzone') {
      return node.to;
    }
    return getEndOfContentZone(node.parent);
  };

  const getPartValues = () => {
    let n = editor.$pos(editor.state.selection.head);
    const l = n.attributes.level;
    const eoc = getEndOfContentZone(n);

    const startOfPart = n.from;
    let endOfPart = eoc - 1;
    let found = false;

    const { doc } = editor.state;

    doc.nodesBetween(startOfPart + n.size, eoc, (node, pos) => {
      if (found) return;
      if (node.type.name === 'heading' && node.attrs.level <= l) {
        if (!found) {
          endOfPart = pos;
          found = true;
        }
      }
    });

    const slice = editor.state.doc.slice(startOfPart, endOfPart);
    const serializer = DOMSerializer.fromSchema(editor.state.schema);
    const container = document.createElement('div');
    slice.content.forEach((node) => {
      container.appendChild(serializer.serializeNode(node));
    });
    const contentInRange = container.innerHTML;

    const data = {
      startOfPart: startOfPart,
      endOfPart: endOfPart,
      content: contentInRange,
      level: l,
      title: container.firstElementChild.outerHTML,
      titleText: container.firstElementChild.textContent,
    };

    return data
  }

  const handleGenerate = () => {
    const data = getPartValues();
    setPartData(data);
    setOpenPartModal(true);
  };

  if (editor) {
    const n = editor.$pos(editor.state.selection.head);
    if (n?.name === 'heading' && isContentZone(n)) {
      partGenerated = getPartValues().content.includes("<p>");
    }
  }

  return (
    <div>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          //placement: "bottom", // Choose 'top', 'bottom', 'left', or 'right'
          //strategy: 'fixed', // Use fixed positioning
          offset: [0, 20], // Adjust the offset as needed
        }}
        shouldShow={({ editor, state }) => {
          const n = editor.$pos(state.selection.head);
          return isContentZone(n) && n.name === 'heading';
        }}
      >
        <div className="text-sm min-w-[200px] overflow-hidden flex flex-col bg-neutral-500 text-white rounded-md">
          <button
            onClick={() => handleGenerate()}
            className="p-2 hover:bg-neutral-600 flex items-center gap-4"
          >
            <SlNote />
            {`${t("Part-content","Contenu de la partie")} ${partGenerated ? t("rewrite","(réécriture)") : ''}`}
          </button>
        </div>
      </BubbleMenu>

      {openPartModal ? (
        <PartModal editor={editor} partData={partData} setOpen={setOpenPartModal} />
      ) : null}
    </div>
  );
};

export default PartBubbleMenu;

import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { resetPasswordInitialValues as initialValues, ResetPasswordSchema as Schema } from "./helper";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../redux/slices/authApiSlice";
import { InputAuth, ButtonAuth } from "../../shared/components/auth";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [reset, { isLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Schema}
        onSubmit={async (values) => {
          try {
            const data={
              id,
              ...values,
            }
            const res = await reset(data).unwrap();
            if (res?.message?.includes('successful')) {
              setTimeout(() => {
                toast.success(t("password-reset-successfully", "Votre mot de passe a bien été changé !"));
                navigate("/login");
              }, 1000);
            }
          } catch (error) {
            toast?.error(error?.data?.detail);
          }
        }}
      >
        {(props) => (
          <form action="#" method="POST" className="space-y-6">
            <InputAuth
              formikProps={props}
              type="password"
              text={t("enter-new-password", "Entrez votre nouveau mot de passe")}
              name="new_password"
              placeholder={t("enter-new-password", "Entrez votre nouveau mot de passe")}
            />

            <InputAuth
              formikProps={props}
              type="password"
              text={t("confirm-new-password", "Confirmez votre nouveau mot de passe")}
              name="confirm_password"
              placeholder={t("confirm-new-password", "Confirmez votre nouveau mot de passe")}
            />

            <ButtonAuth
              action={props.handleSubmit}
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
              text={t("reset-password")}
            />

            <div className="mb-3">
              <p
                className="mt-2 text-sm leading-6 text-gray-500 flex items-center gap-3"
                onClick={() => navigate("/login", { replace: true })}
              >
                {t("back-to-login", "Retourner à se connecter")}
                {" "}
                <p className="cursor-pointer font-semibold text-blue-600">
                  {t("signin")}
                </p>
              </p>
            </div>
          </form>
      )}
      </Formik>
    </>
  );
};

export default ResetPassword;
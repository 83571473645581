import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useGeneratePostPartMutation, useGeneratePostOutlineMutation, useGeneratePostIntroductionMutation } from '../../../redux/slices/featuresApiSlice';
import { DOMSerializer } from 'prosemirror-model';
import { CustomLoader } from '../../../shared/components/others';
import { toast } from 'react-toastify';
import { connectSocket } from '../../../shared/sockets/socket';

const MyEditorComponent = () => {
  const [ws, setWs] = useState(null);
  const [generatePart, { isLoading: generatePartLoading }] = useGeneratePostPartMutation();
  const [generateIntro, { isLoading: generateIntroLoading }] = useGeneratePostIntroductionMutation();

  const [generateOutline, { isLoading: generateOutlineLoading }] = useGeneratePostOutlineMutation();
  const userInfo = useSelector((state) => state?.auth).userInfo;

  const [buffer, setBuffer] = useState(''); // Buffer to accumulate chunks
  const [startPos, setStartPos] = useState(null); // Track start position for insertion
  const [chunkPos, setChunkPos] = useState(null); // Track aggregated content position for insertion
  const [endPos, setEndPos] = useState(null); // Track end position to grab content
  const startPosRef = useRef(null); // Track start position for insertion
  const chunkPosRef = useRef(null); // Track aggregated content position for insertion
  const endPosRef = useRef(null); // Track end position to grab content
  const bufferRef = useRef('');

  // DUMMY DATAS FOR GENERATION
  const style_id = null;
  const company_id = null;
  const product_id = null;
  const subject = "Un article scientifique qui explique rapidement pourquoi les flamants roses sont roses";
  const seo_request =  "pourquoi flamants roses sont roses";
  const outline = "<h2>Introduction</h2><h2>L'origine de la couleur des flamants roses</h2><h3>Qu'est-ce qui rend les flamants roses ?</h3><h4>Le rôle de la caroténoïde dans l'alimentation</h4><h4>La métabolisation des pigments par le corps</h4><h3>Différences entre les espèces de flamants</h3><h4>Flamants roses vs autres espèces</h4><h4>Comment l'habitat influence la couleur</h4><h2>L'impact de l'alimentation sur le plumage</h2><h3>Les sources alimentaires riches en caroténoïdes</h3><h4>Les types de crustacés et d'algues</h4><h4>Les facteurs influençant la présence de ces sources</h4><h3>Variations saisonnières de la coloration</h3><h4>Les changements dans le régime alimentaire</h4><h4>L'impact des migrations sur la couleur</h4><h2>Les implications écologiques de la coloration</h2><h3>La couleur comme indicateur de santé</h3><h4>Le lien entre la couleur et le succès reproducteur</h4><h4>Les indicateurs de la pollution de l'habitat</h4><h3>Les défis causés par le changement climatique</h3><h4>Effets sur la disponibilité des ressources alimentaires</h4><h4>Stratégies de conservation pour les flamants roses</h4><h2>Conclusion</h2>"

  // TEST EDITOR
  const editor = useEditor({
    // Setup your editor extensions here
    extensions: [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: "Votre plan d'article ...",
        })
    ],

    content: outline,
  });

  // WEBSOCKET LISTENER
  useEffect(() => {
    // Connect to WebSocket if user is logged in and WebSocket is not open
    if (userInfo && !ws) {
      const socket = connectSocket(userInfo.unique_id); // Establish WebSocket connection
      setWs(socket);

      console.log("SOCKET CONNECTED")

      socket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (!data.isRewrite) {
          // Append new chunk to buffer and insert into editor
          handleChunkArrival(data.data);
        }
      };
    }
  }, [userInfo, ws]);

  const cleanGeneratedContent = (content) => content.replace(/>\s*\n\s*</g, '><');

  // FUNCTION TO HANDLE CHUNKS
  const handleChunkArrival = (chunk) => {
    if (chunkPosRef.current === null) {
      // First chunk: delete part and set first chunk
      // editor.chain().focus()
      // .deleteRange({ from: startPosRef.current, to: endPosRef.current })
      // .insertContentAt(startPosRef.current, chunk)
      // .run();

      editor.chain().focus()
      .insertContentAt(startPosRef.current, cleanGeneratedContent(chunk))
      .run();
      
      // Set current chunk end position
      // setChunkPos((prevChunkPos) => editor.state.selection.to);
      chunkPosRef.current = editor.state.selection.to;
    } else {
      // Subsequent chunks: replace content between start and end positions with full buffer
      bufferRef.current = bufferRef.current + chunk;
      console.log(bufferRef)
      console.log(startPosRef)
      console.log(chunkPosRef)

      editor
        .chain()
        .focus()
        .deleteRange({ from: startPosRef.current, to: editor.state.selection.to }) // Delete previous buffer content
        .insertContentAt(startPosRef.current, cleanGeneratedContent(bufferRef.current)) // Insert the updated buffer
        .run();


      console.log("GENERATED NEXT middle")

      // Update end position to reflect new end of buffer
      // setChunkPos((prevChunkPos) => startPosRef + buffer.length);
      chunkPosRef.current = startPosRef.current + editor.state.selection.to;

      console.log("GENERATED NEXT end")
    }

    // Update the buffer with the new chunk
    // setBuffer(buffer + chunk);
    //bufferRef.current = bufferRef.current + chunk;
  };


  // -----------------------------------------------------
  // FUNCTIONS THAT WILL BE USEFUL IN YOUR CASE

  // FUNCTION TO SET PART START AND END POSITION
  const setStartEndPositions = () => {
    const { state } = editor;
    const { selection } = state;

    const position = selection.from; // Start from current cursor position
    const { doc } = state; // Document, !!! Probably need to change it in your case !!!

    let currentNode = null;
    let currentLevel = null;
    let start = null;
    let end = doc.content.size; // Default to end of the document if no next node is found
    let found = false;

    // Find the current heading node type and position
    doc.nodesBetween(position, position, (node, pos) => {
      if (node.isBlock && node.type.name === 'heading') {
        start = pos;
        currentNode = node;
        currentLevel = node.attrs.level;
      }
    });

    // Find the next heading of the same or higher level
    doc.nodesBetween(start + currentNode.nodeSize, doc.content.size, (node, pos) => {
      if (found) return false;
      
      if (node.isBlock && node.type.name === 'heading') {
        if (node.attrs.level <= currentLevel) {
          end = pos; // Set the endpoint to the start of this next heading
          found = true; // Stop traversal once the endpoint is found
        }
      }
    });
  
    setStartPos((prevStartPos) => start);
    setEndPos((prevEndPos) => end);

    startPosRef.current = start;
    endPosRef.current = end;
  
    // Grab the HTML content between startPos and endPos
    const slice = editor.state.doc.slice(start, end);
    const serializer = DOMSerializer.fromSchema(editor.state.schema);
    const container = document.createElement("div");
    slice.content.forEach((node) => {
      container.appendChild(serializer.serializeNode(node));
    });
    const content = container.innerHTML;

    return {content, start, end};
  };
  
  // GENERATION FUNCTION
  const GeneratePart = async () => {
    try {
        if (userInfo?.openai_api_key === null) {
            toast.error("Vous devez ajouter une clé OpenAI avant de générer un contenu !");
        } else {
          // Set start and end pos and get content
          const {content, start, end} = setStartEndPositions();
          console.log(content)
          // !!! ADDITIONAL FILTERING to choose rewrite or not and to split part and part_names
          // const payload = {
          //   style_id: style_id,
          //   company_id: company_id,
          //   product_id: product_id,
          //   subject: subject,
          //   seo_request: seo_request,
          //   outline: outline,
          //   part_name: content
          // }
          const payload = {
            style_id: style_id,
            company_id: company_id,
            product_id: product_id,
            subject: subject,
            seo_request: seo_request,
            outline: outline,
            length: "courte"
          }
          const fullPayload = {...payload, ...{ stream: true }}
          const res = await generateIntro(fullPayload).unwrap();
          console.log(res);
          if (res.status === 200) {
            // Replace the default content with the newly generated content
            editor
            .chain()
            .focus()
            .deleteRange({ from: start, to: end }) // !!! IN YOUR CASE HERE YOU WILL SET "to" TO endPos, AS I TOLD YOU IN VIDEO I JUST DO THAT DIFFERENT THING WHEN I USE CHUNKS
            .insertContentAt(start, cleanGeneratedContent(res.data))
            .run();

            // Reset all the generation elements to avoid problems for future generation
            setStartPos(null);
            setEndPos(null);
            setChunkPos(null);
            setBuffer("");
          }
        }
    } catch (error) {
        toast.error("Error occur while generating data");
    }
  }
  // -----------------------------------------------------


    // GENERATION FUNCTION
    const GenerateOutline = async () => {
      // try {
          if (userInfo?.openai_api_key === null) {
              toast.error("Vous devez ajouter une clé OpenAI avant de générer un contenu !");
          } else {
            // Set start and end pos and get content
            const start = editor.state.selection.to
            startPosRef.current = start;
            // !!! ADDITIONAL FILTERING to choose rewrite or not and to split part and part_names
            const payload = {
              style_id: style_id,
              company_id: company_id,
              product_id: product_id,
              subject: subject,
              seo_request: seo_request,
              nb_words: 1000,
              nb_parts: 3,
              is_h1_tag: false
            }
            const fullPayload = {...payload, ...{ stream: true }}
            console.log(payload)
            const res = await generateOutline(fullPayload).unwrap();
            if (res.status === 200) {
              // Replace the default content with the newly generated content
              editor
              .chain()
              .focus()
              .insertContentAt(start, res.data)
              .run();
  
              // Reset all the generation elements to avoid problems for future generation
              setStartPos(null);
              setEndPos(null);
              setChunkPos(null);
              setBuffer("");
            }
          }
      // } catch (error) {
      //     toast.error("Error occur while generating data");
      // }
    }


  // JUST THE DEFAULT FUNCTION USED FOR MY TEST BUTTON
  const verifySetStartEnd = () => {
    const thecontent = setStartEndPositions();

    console.log("CONTENT OF PART")
    console.log(thecontent);
  }

  return (
    <>
      <style>
        {`
          .ProseMirror h1 {
            font-size: 2em;
          }

          .ProseMirror h2 {
            font-size: 1.75em;
          }

          .ProseMirror h3 {
            font-size: 1.5em;
          }

          .ProseMirror h4 {
            font-size: 1.25em;
          }

          .ProseMirror h5 {
            font-size: 1em;
          }

          .ProseMirror h6 {
            font-size: 0.875em;
          }
        `}
      </style>
      <button onClick={verifySetStartEnd}>TestPart</button>
      <button onClick={GeneratePart}>
        {generatePartLoading ? (
          <CustomLoader />
        ) : (
          'Générer la partie'
        )}
      </button>
      <button onClick={GenerateOutline}>
        {generateOutlineLoading ? (
          <CustomLoader />
        ) : (
          'Générer une outline'
        )}
      </button>
      <EditorContent editor={editor} />;
    </>
  )
};


export default MyEditorComponent;
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import UrlSlug from "./UrlSlug";
import H1Title from "./h1-title-panel";
import Content from "./content-panel";
import Metadata from "./metadata/metadata-panel";
import Semantics from "./semantics-panel";
import Linking from "./linking-panel";
import Medias from "./medias-panel";
import Outline from "./Outline";
import { FormikFunction } from "./formik-function/formik-function";
import { useEffect } from "react";
import { dispatch } from "../../../../../redux/store";
import { setIsPanelOpen } from "../../../../../redux/slices/panelSlice";

export const SidePanel = () => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(setIsPanelOpen(false));
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        color: "black",
        overflow: "scroll",
        px: "15px",
        pt: 3,
        width: { sm: "350px", xs: "auto" },
        bgcolor: "whitesmoke",
        borderLeft: "3px solid #e5e7eb",
      }}
    >
      <div>
        <p className="text-center font-semibold mb-4">{t('Page-analysis', "Analyse de la page")}</p>

        <div className="mb-4">
          <p className="font-semibold mb-2">{t("Preparation", "Préparation")}</p>
          <div>
            <Outline />
          </div>
        </div>

        <div className="mb-4">
          <p className="font-semibold mb-2">{t("Redaction", "Rédaction")}</p>
          <div className="flex flex-col gap-2">
            <UrlSlug />
            <H1Title />
            <Content />
            <Metadata />
          </div>
        </div>

        <div>
          <p className="font-semibold mb-2">{t("Optimization", "Optimisation")}</p>
          <div className="flex flex-col gap-2">
            <Semantics />
            <Linking />
            <Medias />
          </div>
        </div>
      </div>
    </Box>
  );
};

const DesktopSidePanel = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        right: 0,
        left: { sm: "unset", xs: 0 },
        zIndex: 9999,
        mt: "48px",
        //display: open ? 'unset ' : 'none',
      }}
    >
      <SidePanel />
    </Box>
  );
};

export default DesktopSidePanel;

import { useTranslation } from "react-i18next";
import { BubbleMenu } from '@tiptap/react';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import ConclusionModal from './conclusion-modal';
import { SlNote } from 'react-icons/sl';
import { useWebSocket } from "../../WebSocketContext";

const ConclusionBubbleMenu = ({ editor }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const {
    openConclusionModal,
    setOpenConclusionModal
  } = useWebSocket()

  const isConclusionZone = (node) => {
    if (!node || node?.name === 'doc') return false;
    if (node?.name === 'conclusionzone') {
      return true;
    }
    return isConclusionZone(node.parent);
  };

  return (
    <div>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          //placement: "bottom", // Choose 'top', 'bottom', 'left', or 'right'
          strategy: 'fixed', // Use fixed positioning
          offset: [0, 20], // Adjust the offset as needed
        }}
        shouldShow={({ editor, state }) => {
          const n = editor.$pos(state.selection.head);
          return isConclusionZone(n);
        }}
      >
        <div className="min-w-[200px] text-sm overflow-hidden flex flex-col bg-neutral-500 text-white rounded-md ">
          {/* Customize your bubble menu content here */}
          <button
            onClick={() => setOpenConclusionModal(true)}
            className="p-2 hover:bg-neutral-600 flex items-center gap-4"
          >
            <SlNote />
            {!values.conclusion.includes("<p>") ? t("Conclusion", "Conclusion") : t("Conclusion-Rewrite", "Conclusion (réécriture)")}
          </button>

          <button
            //onClick={() => setOpen(true)}
            className="p-2 hover:bg-neutral-600 flex items-center gap-4"
          >
            <SlNote />
            {t("Personalized", "Personnalisé")}
          </button>
        </div>
      </BubbleMenu>

      {openConclusionModal ? <ConclusionModal setOpen={setOpenConclusionModal} /> : null}
    </div>
  );
};

export default ConclusionBubbleMenu;

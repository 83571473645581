import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PaymentRoute = () => {
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const notify = () => {
    toast.error('You need to have an active paid plan to access this page.');
  };
  return (
    <>
      {userInfo.payment_status === 'pending' ||
      userInfo.payment_status === 'active' ? (
        <Outlet />
      ) : (
        <>
          {notify()} <Navigate to='/pricing' replace />
        </>
      )}
    </>
  );
};
export default PaymentRoute;

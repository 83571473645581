import { apiSlice } from "./apiSlice";
import { ENDPOINTS } from "../../shared/constants/urls";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ formData, rememberMe }) => ({
        url: `${ENDPOINTS.AUTH_LOGIN}?remember_me=${rememberMe}`,
        method: "POST",
        body: formData,
        credentials: 'include'
      }),
      extraOptions: { requiresAuth: false },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: ENDPOINTS.AUTH_REFRESH_TOKEN,
        method: "POST",
        credentials: 'include'
      }),
      extraOptions: { requiresAuth: false },
    }),
    register:builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.AUTH_REGISTER,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: false },
    }),
    googleLogin: builder.mutation({
      query: () => ({
        url: ENDPOINTS.AUTH_GOOGLE_LOGIN,
        method: 'GET',
      }),
      extraOptions: { requiresAuth: false },
    }),
    googleCallback: builder.mutation({
      query: (code) => ({
        url: `${ENDPOINTS.AUTH_GOOGLE_CALLBACK}?code=${code}`,
        method: 'GET',
      }),
      extraOptions: { requiresAuth: false },
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${ENDPOINTS.AUTH_LOGOUT}`,
        method: "POST",
        credentials: 'include'
      }),
      extraOptions: { requiresAuth: false },
    }),
    resendVerificationEmail:builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.AUTH_RESEND_EMAIL_VERIFICATION,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: false },
    }),
    forgotPassword:builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.AUTH_FORGOT_PASSWORD,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: false },
    }),
    resetPassword:builder.mutation({
      query: (data) => {
        const{id}=data
        const newData= {
          confirm_password:data.confirm_password,
          new_password:data.new_password
        }
        return {
          url: `${ENDPOINTS.AUTH_RESET_PASSWORD}?token=${id}`,
          method: "POST",
          body: newData,
        };
      },
      extraOptions: { requiresAuth: false },
    }),
    getUserInfos: builder.query({
      query: () => ({
        url: ENDPOINTS.AUTH_ME,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    })
  }),
});

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useRegisterMutation,
  useGoogleLoginMutation,
  useGoogleCallbackMutation,
  useLogoutMutation,
  // useSetPasswordMutation,
  useResendVerificationEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetUserInfosQuery
} = authApiSlice;

import { useTranslation } from 'react-i18next';
import { BubbleMenu } from '@tiptap/react';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import IntroductionModal from './introduction-modal';
import { SlNote } from 'react-icons/sl';
import { useWebSocket } from "../../WebSocketContext";

const IntroZoneBubbleMenu = ({ editor }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const {
    openIntroductionModal,
    setOpenIntroductionModal
  } = useWebSocket()

  const isIntroZone = (node) => {
    if (!node || node?.name === 'doc') return false;
    if (node?.name === 'introzone') {
      return true;
    }
    return isIntroZone(node.parent);
  };

  return (
    <div>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          //placement: "bottom", // Choose 'top', 'bottom', 'left', or 'right'
          //strategy: 'fixed', // Use fixed positioning
          offset: [0, 20], // Adjust the offset as needed
        }}
        shouldShow={({ editor, state }) => {
          const n = editor.$pos(state.selection.head);
          return isIntroZone(n);
        }}
      >
        <div className="min-w-[200px] text-sm overflow-hidden flex flex-col bg-neutral-500 text-white rounded-md">
          {/* Customize your bubble menu content here */}
          <button
            onClick={() => setOpenIntroductionModal(true)}
            className="p-2 hover:bg-neutral-600 flex items-center gap-4"
          >
            <SlNote />
            {!values.introduction.includes("<p>")
              ? t('Introduction', 'Introduction')
              : t('Introduction-Rewrite', 'Introduction (réécriture)')}
          </button>

          <button
            //onClick={() => setOpen(true)}
            className="p-2 hover:bg-neutral-600 flex items-center gap-4"
          >
            <SlNote />
            {t("Personalized", "Personnalisé")}
          </button>
        </div>
      </BubbleMenu>

      {openIntroductionModal ? <IntroductionModal /> : null}
    </div>
  );
};

export default IntroZoneBubbleMenu;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../shared/components/others';
import { Box } from '@mui/material';
import { useGetPostEditorBlogMutation } from '../../redux/slices/postEditorBlogSlice';
import Editor from './Editor';

const UpdatePostEditor = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [getPostEditor, { isLoading: getLoading }] = useGetPostEditorBlogMutation();

  const handleGetSingle = async (id) => {
    try {
      const res = await getPostEditor({ id }).unwrap();
      console.log("BACKEND ELEMENT")
      console.log(res)
      const sanitizedData = Object.fromEntries(
        Object.entries(res?.data || {}).map(([key, value]) => [
          key,
          value === null ? '' : value,
        ])
      );
      setBlogPost(sanitizedData);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetSingle(id);
  }, [id]);

  return (
    <Box>
      {getLoading || blogPost === null ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'65vh'}
        >
          <CustomLoader />
        </Box>
      ) : (  
        <Editor blogPost={blogPost} />
      )}
    </Box>
  );
};

export default UpdatePostEditor;

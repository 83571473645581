import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import { EntrePriseInitialValues, EntrePriseSchema } from "../helper";
import {
  useCompanyVariableMutation,
  useDeleteCompanyVariableMutation,
  useGetAllCompanyVariablesQuery,
  useGetSingleCompanyMutation,
  useUpdateSingleCompanyMutation,
} from "../../../redux/slices/profileApiSlice";
import { ClipLoader } from "react-spinners";
import BusinessIcon from "@mui/icons-material/Business";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../../shared/components/generationVariables/DeleteDialog";
import { InputProfile } from "../../../shared/components/profile";

const Company = () => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [selectId, setSelectId] = useState("");
  const [onEdit, setOnEdit] = useState(false);
  const [editCompany, setEditCompany] = useState(null);
  const [open, setOpen] = useState(false);
  const [company, { isLoading }] = useCompanyVariableMutation();
  const [singleCompany, { isLoading: loadingSingleCompany }] = useGetSingleCompanyMutation();
  const [updateCompany, { isLoading: updateCompanyLoading }] = useUpdateSingleCompanyMutation();
  const { data: companyVariables,isLoading: loading, refetch } = useGetAllCompanyVariablesQuery();
  const [deleteCompanyVariable, { isLoading: loadingDelete }] = useDeleteCompanyVariableMutation();

  useEffect(() => {
    refetch();
  }, [counter]);

  // To open the delete modal
  const handleDeleteCompanyVariable = async (id) => {
    setOpen(true);
    setSelectId(id);
  };

  // To delete the object
  const handleDelete = async () => {
    try {
      const res = await deleteCompanyVariable(selectId);
      if (res) {
        toast.success("Deleted successfully");
        setOpen(false);
        setOnEdit(false);
        setEditCompany(null);
        refetch();
      }
    } catch (error) {
      toast.error(error?.data?.detail || "Something went wrong");
      setOpen(false);
    }
  };

  // To edit the product
  const handleCompanyEdit = async (id) => {
    if (id) {
      try {
        setOnEdit(true);
        const res = await singleCompany({ id }).unwrap();
        setEditCompany(res);
      } catch (error) {}
    }
  };

  // To create a new product
  const handleCreateNew = async (resetForm) => {
    setOnEdit(false);
    setEditCompany(null);
    refetch();
    resetForm();
  };

  return (
    <div className="bg-white py-10 rounded-2xl px-6 mx-auto shadow-default items-center">

      <div className="flex items-center">
        <BusinessIcon sx={{ width: "35px", height: "35px" }} />
        <p className="md:text-[28px] sm:text-lg font-medium ml-3">{t("Company/Website", "Entreprise/Site web")}</p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mt-4">
        <Formik
          initialValues={editCompany ? editCompany : EntrePriseInitialValues}
          enableReinitialize={true}
          validationSchema={EntrePriseSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              if (onEdit) {
                const res = await updateCompany(values).unwrap();
                if (res?.status === 200) {
                  toast.success("Update successfully");
                  setCounter(counter + 1);
                }
              } else {
                const res = await company(values).unwrap();
                setCounter((prev) => prev + 1);
                if (res?.status === 201) {
                  toast.success("Created successfully");
                  handleCompanyEdit(res.data.id);
                }
              }
            } catch (error) {
              toast?.error(error?.data?.detail);
            }
          }}
        >
          {(props) => (
            <div className="flex items-center justify-center">
              <form onSubmit={props.handleSubmit} className="w-full">
                <div className="grid md:grid-cols-1 gap-y-5 w-full">
                  
                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("company-website-key", "Nom de l'entreprise/du site web (utilisé pour vous permettre de l'identifier)")}
                      name="key"
                      placeholder={t("example-company-key", "Ex: BotBlogR blog")}
                    />
                  </div>

                  <div>
                    <div className="flex items-center gap-9">
                      <label className="relative cursor-pointer block w-fit">
                        <input
                          className="peer sr-only"
                          defaultValue=""
                          type="checkbox"
                          checked={props.values.is_default}
                          onChange={props.handleChange}
                          name="is_default"
                        />
                        <div className="peer h-[14px] w-9 rounded-full bg-gray_local-400 after:absolute after:top-[-3px] after:left-0 after:h-5 after:w-5 after:rounded-full after:shadow-2xl after:bg-[#e1e1e1] after:transition-all after:content-[''] peer-checked:bg-[#316694] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none" />
                      </label>
                      <p className="text-sm">{t("choose-as-default-company-or-website", "Choisir comme entreprise/site web par défaut")}</p>
                    </div>
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("company-or-website-name", "Nom de l'entreprise/du site web (utilisé pour la génération)")}
                      name="name"
                      placeholder={t("example-company-name", "Ex: BotBlogR")}
                    />
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("company-or-website-description", "Description de l'entreprise/du site web")}
                      name="description"
                      placeholder={t("example-company-description", "Ex: Une entreprise offrant des solutions d'IA et d'automatisation pour booster son SEO ...")}
                      maxLength={250}
                      multiline={true}
                    />
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("company-audience", "Audience cible")}
                      name="audience"
                      placeholder={t("example-company-audience", "Ex: Rédacteurs web débutants ou confirmés, créateurs de sites web ...")}
                      maxLength={250}
                      multiline={true}
                    />
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("company-or-website-url", "URL du site web/de l'entreprise")}
                      name="website"
                      placeholder={t("example-company-website", "Ex: https://botblogr.com")}
                    />
                  </div>

                  <div className="w-full flex items-center justify-between gap-x-4">
                    <button
                      className="px-5 py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
                      onClick={props.handleSubmit}
                      disabled={isLoading || updateCompanyLoading}
                    >
                      {isLoading || updateCompanyLoading ? (
                        <ClipLoader size={25} color="white" />
                      ) : (
                        t("save-all-changes","Sauvegarder les changements")
                      )}
                    </button>
                    <button
                      type="button"
                      className="px-5 py-[6px] hover:bg-success_hover bg-success text-white rounded font-light"
                      onClick={() => handleCreateNew(props.resetForm)}
                    >
                      {t("create-new-company-or-website","Créer un nouveau site web/entreprise")}
                    </button>
                  </div>

                </div>
              </form>
            </div>
          )}
        </Formik>

        <div className="container">
          <div className="mt-6">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs text-white uppercase bg-blue-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 rounded-s-md text-base"
                    >
                      {t("name", "Nom")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 rounded-e-md w-48 text-base"
                    >
                      {t("action", "Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companyVariables?.map((item) => (
                    <>
                      <tr className="odd:bg-white even:bg-black/10">
                        <td
                          scope="col"
                          className="px-6 py-4 text-sm font-medium text-gray-900 break-all"
                        >
                          {item.key}
                        </td>
                        <td
                          scope="col"
                          className="px-6 py-4 font-medium text-gray-900 flex gap-6 w-48"
                        >
                          <button
                            className="px-5 py-[6px] hover:bg-blue-200 bg-blue-100 text-white rounded font-light"
                            onClick={() => handleCompanyEdit(item?.id)}
                          >
                            <EditIcon />
                          </button>
                          <button
                            className="px-5 py-[6px] hover:bg-[#f44336] bg-[#ff574b] text-white rounded font-light"
                            onClick={() => handleDeleteCompanyVariable(item?.id)}
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <DeleteDialog
        open={open}
        text={t("are-you-sure-to-delete-company-or-website", "Es-tu sûr de vouloir supprimer ce site web/entreprise ?")}
        handleDelete={handleDelete}
        setOpen={setOpen}
        loading={loadingDelete}
        title={t("delete-company-or-website", "Supprimer ce site web/entreprise")}
      />
    </div>
  );
};

export default Company;
import { FaSave, FaTrash } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import {
  useDeleteSinglePostEditorBlogMutation,
  useUpdateSinglePostEditorBlogMutation,
} from '../../../../../redux/slices/postEditorBlogSlice';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const PostEditorActions = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const formikProps = useFormikContext();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [savePost, { isLoading: isSaving }] =
    useUpdateSinglePostEditorBlogMutation();
  const [deletePostEditor, { isLoading: isDeleting }] =
    useDeleteSinglePostEditorBlogMutation();

  const handleSave = async () => {
    if (formikProps.dirty && formikProps.isValid) {
      try {
        const values = formikProps.values;
        const payload = Object.fromEntries(
          Object.entries(values).map(([key, value]) => [
            key,
            value === null || value === '' ? null : value,
          ])
        );
        console.log(payload);
        const res = await savePost(payload).unwrap();
        if (res?.status === 200) {
          toast.success('Updated successfully');
          formikProps.resetForm({ values });
        }
      } catch (err) {
        console.log(err);
        toast.error('Error saving data');
      }
    } else {
      console.log(formikProps.isValid)
      toast.warning('Please complete your Form Fields');
    }
  };

  const handleDeleteBlogPost = async (id) => {
    try {
      const res = await deletePostEditor({ id }).unwrap();
      //refetch();
      toast.success('Post Deleted Successfully');
      navigate('/blog-posts');
    } catch (error) {
      console.log(error);
      toast.error('Error Occur while deleting');
    }
  };

  return (
    <div className={`flex gap-1 items-center ${isLarge ? '' : 'mr-8'}`}>
      <LoadingButton
        onClick={handleSave}
        size='small'
        disabled={isSaving || !formikProps.dirty}
        loading={isSaving}
        sx={{
          backgroundColor: '#22c55e',
          textTransform: 'none',
          color: 'white',
          ':hover': {
            backgroundColor: '#16a34a',
          },
        }}
        startIcon={<FaSave fill='white' />}
      >
        {/* <FaSave fill='white' /> */}
        <p className='hidden  sm:block'>{t("Save","Sauvegarder")}</p>
      </LoadingButton>

      <LoadingButton
        size='small'
        onClick={() => setOpen(true)}
        disabled={isDeleting}
        loading={isDeleting}
        sx={{
          backgroundColor: '#ef4444',
          textTransform: 'none',
          color: 'white',
          ':hover': {
            backgroundColor: '#dc2626',
          },
        }}
        startIcon={<FaTrash fill='white' />}
      >
        <p className='hidden sm:block'>{t("Delete","Supprimer")}</p>
      </LoadingButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className='relative z-[9999]'
      >
        <DialogBackdrop
          transition
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
        />
        <div className='fixed inset-0 z-[999] w-screen overflow-y-auto bg-black/50'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <DialogPanel
              transition
              className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-2xl sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'
            >
              <div className=''>
                <DialogTitle
                  as='h3'
                  className='text-base font-semibold leading-6 text-gray-900'
                >
                  <div className='flex items-center gap-3'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error/30 sm:mx-0 sm:h-10 sm:w-10'>
                      <PriorityHighIcon
                        aria-hidden='true'
                        className='h-8 w-8 text-error'
                      />
                    </div>
                    <p className='text-base font-semibold leading-6 text-black/40'>
                      {t('delete-blog-post', "Supprimer l'article de blog")}
                    </p>
                  </div>
                </DialogTitle>
                <p className='text-base text-black text-left mt-5'>
                  'Êtes-vous sûr de vouloir supprimer votre article de blog ?
                  Les données seront effacées et vous ne pourrez plus les
                  récupérer.'
                </p>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    type='button'
                    disabled={isDeleting}
                    onClick={() => handleDeleteBlogPost(id, true)}
                    className='inline-flex w-full justify-center rounded-md bg-error px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
                  >
                    Supprimer
                  </button>
                  <button
                    type='button'
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PostEditorActions;

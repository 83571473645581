import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, Stack, IconButton } from '@mui/material';
import { useFormik, useFormikContext } from 'formik';
import GlobalTextField from '../../FeaturesTemplate/FeaturesFields/GlobalTextField';
import GlobalNumberField from '../../FeaturesTemplate/FeaturesFields/GlobalNumberField';
import { Add, Check, Close } from '@mui/icons-material';
import { setIsPanelOpen } from '../../../../../../redux/slices/panelSlice';
import { setOpenUrlSlug } from '../../../../../../redux/slices/panelSlice';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../../../../redux/store';
import '../../Toolbar/Toolbar.css';
import { globalValidationConfig } from '../formik-function/helpers';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useGeneratePostURLSlugMutation } from '../../../../../../redux/slices/featuresApiSlice';
import { BsMagic } from 'react-icons/bs';
import { LaunchAnalysisBtn } from '../../Others';

const UrlSlug = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const formikProps = useFormikContext();
  const [generatePostURLSlug, { isLoading }] = useGeneratePostURLSlugMutation();
  const open = useSelector((state) => state.panelSlice.openUrlSlug);
  const isPanelOpen = useSelector((state) => state.panelSlice.isPanelOpen);

  const path =
    formikProps.values.url_path === ''
      ? 'https://botblogr/seo'
      : formikProps.values.url_path;
  const urlSlug =
    formikProps.values.url_slug === ''
      ? t("slug-placeholder", 'ecrire-article-seo-avec-ia')
      : formikProps.values.url_slug;
  const urlSlugValue = `${path}/${urlSlug}`;

  const handleOpen = () => {
    if (!isPanelOpen) {
      dispatch(setOpenUrlSlug(true));
      dispatch(setIsPanelOpen(true));
    }
  };
  const handleClose = () => {
    dispatch(setOpenUrlSlug(false));
    dispatch(setIsPanelOpen(false));
  };

  const handleGenerateUrlSlug = async () => {
    try {
      const payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        url_path: formikProps.values.url_path,
        nb_outputs: formikProps.values.nb_url_slug,
        outputs: formikProps.values.url_slug_ideas,
      };
      if (formikProps.values.url_slug !== '') {
        payload['url_slug'] = formikProps.values.url_slug;
      }

      console.log('url slug payload: ', payload);

      const res = await generatePostURLSlug(payload).unwrap();

      // const res = {
      //   data: [
      //     'observer-animaux-sauvages-france',
      //     'zones-observation-animaux-nature-france',
      //     'conseils-observer-animaux-sauvages',
      //   ],
      //   status: 200,
      // };

      if (res?.status !== 200) {
        toast.error('Error while generating url slug');
        return;
      }

      console.log('url slug res: ', res);

      formikProps.setFieldValue('url_slug_ideas', res?.data);
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else {
        toast.error(t("something-went-wrong", "Une erreur s'est produite"))
      }
      console.log(error);
    }
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: 'fixed',
            overflow: { md: 'unset', xs: 'scroll' },
            height: { md: '100vh', xs: '100%' },
            width: '100%',
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            mt: { md: '48px !important', xs: 'unset' },
            left: 0,
            top: 0,
            bgcolor: '#000000a1',
          }}
        >
          {/* MODAL */}
          <Box
            sx={{
              width: { md: 'calc(100vw - 350px)', xs: '100%' },
              display: 'flex',
              justifyContent: 'center',
              height: 'auto',
              overflow: { md: 'auto', xs: 'unset' },
            }}
          >
            <Box sx={{ width: '100%', my: { md: 'unset', xs: '40px' } }}>
              <div className="urlslug-wrapper">
                <input
                  readOnly
                  className="urlslug-input"
                  type="text"
                  value={urlSlugValue}
                />
              </div>
              <Box
                sx={{
                  height: 'fit-content',
                  mx: 'auto',
                  mt: 4,
                  bgcolor: 'white',
                  maxWidth: '900px',
                  width: '95%',
                  borderRadius: 3,
                }}
              >
                {/* <Box sx={{ overflowX: 'scroll', scrollbarWidth: 'thin' }}> */}
                {/* <Box sx={{ minWidth: '450px' }}> */}
                <Box
                  sx={{
                    py: 2,
                    px: 3,
                    position: 'relative',
                    borderBottom: '2px solid #e5e7eb',
                  }}
                >
                  <p className="text-2xl font-semibold">Slug d'URL</p>
                  <Box
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      cursor: 'pointer',
                      right: 0,
                      top: 0,
                      p: 2,
                    }}
                  >
                    <Close sx={{ color: 'black', fontSize: 32 }} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    bgcolor: 'white',
                    px: 3,
                    py: 2,
                  }}
                >
                  <form>
                    <Grid container spacing={2}>
                      <Grid item lg={12} xs={12}>
                        <p>Path Url</p>
                        <GlobalTextField
                          formikProps={formikProps}
                          name="url_path"
                          placeholder="Ex: https://botblogr/seo/"
                          isRequired={globalValidationConfig.url_path.required}
                          maxLength={globalValidationConfig.url_path.max}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <p>Slug d'URL</p>
                        <GlobalTextField
                          formikProps={formikProps}
                          name="url_slug"
                          placeholder={"Ex: "+t("slug-placeholder", 'ecrire-article-seo-avec-ia')}
                          isRequired={globalValidationConfig.url_slug.required}
                          maxLength={globalValidationConfig.url_slug.max}
                        />
                        <div className="flex">
                          <LoadingButton
                            startIcon={<BsMagic />}
                            loading={isLoading}
                            onClick={handleGenerateUrlSlug}
                            variant="contained"
                            sx={{
                              textTransform: 'none',
                              height: '100%',
                              mt: 2,
                              bgcolor: '#8b5cf6',
                            }}
                          >
                            {t("Generate-ideas-for-slug", "Générer des idées de slug d'URL")}
                          </LoadingButton>

                          <GlobalNumberField
                            style={{
                              bgcolor: 'white',
                              borderRadius: 'none !important',
                            }}
                            size="small"
                            formikProps={formikProps}
                            name={'nb_url_slug'}
                            isRequired={
                              globalValidationConfig.nb_url_slug.required
                            }
                            minValue={globalValidationConfig.nb_url_slug.min}
                            maxValue={globalValidationConfig.nb_url_slug.max}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <div className="flex flex-col max-h-[275px] overflow-auto mt-2 pr-2 gap-4">
                          {formikProps.values.url_slug_ideas?.map(
                            (item, index) => (
                              <div className="flex flex-col">
                                <div className="ml-auto">
                                  <Check
                                    className="cursor-pointer"
                                    onClick={() =>
                                      formikProps.setFieldValue(
                                        'url_slug',
                                        item
                                      )
                                    }
                                  />

                                  <Close
                                    className="cursor-pointer"
                                    onClick={() =>
                                      formikProps.setFieldValue(
                                        'url_slug_ideas',
                                        formikProps.values.url_slug_ideas.filter(
                                          (i) => i !== item
                                        )
                                      )
                                    }
                                  />
                                </div>
                                <div className="bg-neutral-200 font-semibold p-4 rounded-md">
                                  {item}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
                {/* </Box> */}
                {/* </Box> */}
              </Box>
            </Box>
          </Box>

          {/* PANEL */}
          <Box
            sx={{
              backgroundColor: '#303539',
              height: '100vh',
              //overflow: 'auto',
              width: { md: '350px', xs: '100%' },
              color: 'white',
              pb: 5,
            }}
          >
            <Box
              sx={{
                backgroundColor: '#376f41',
                p: 2,
                position: 'relative',
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: 0,
                  top: 0,
                  p: 2,
                }}
              >
                <Close sx={{ color: 'white', fontSize: 32 }} />
              </Box>

              <h1 className="text-2xl mb-2">{t('URL-Slug', "Slug d'URL")}</h1>

              <p className="text-xs mb-2">
                {t(
                  'tutorial-text-url-slug',
                  'Le choix d’un bon slug d’URL est important pour le SEO, mais surtout pour l’expérience utilisateur, qui influe sur le classement de votre page. Suivez nos indications, générez des idées et inspirez-vous de la la concurrence !'
                )}
              </p>

              <p className="text-xs">
                {t('For-more-info:', "Pour plus d'infos :")}{' '}
                <span>
                  <a className="text-blue-500 underline cursor-pointer">
                    {
                      ('url-slug-optimization-guide',
                      'Slug d’URL optimisé pour le SEO : le Guide Complet 2024')
                    }
                  </a>
                </span>
              </p>
            </Box>

            <LaunchAnalysisBtn
              userInfo={userInfo}
              formikProps={formikProps}
              disabled={true}
            />
          </Box>
        </Box>
      ) : (
        <div onClick={handleOpen} className="panel-button">
          {t('URL-Slug', "Slug d'URL")}
        </div>
      )}
    </>
  );
};

export default UrlSlug;

import "./index.css";
import { useTranslation } from "react-i18next";
import { dispatch } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { IoMdArrowBack, IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import {
  setIsPanelOpen,
  setOpenOutline,
} from "../../../../../redux/slices/panelSlice";
import PostEditorActions from "./post-editor-actions";

const SettingsPopover = ({ editor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormikContext();
  const isPanelOpen = useSelector((state) => state?.panelSlice.isPanelOpen);

  const handleOpenOutline = () => {
    if (!isPanelOpen) {
      dispatch(setOpenOutline(true));
      dispatch(setIsPanelOpen(true));
    }
  };

  return (
    <div className="settings-container flex justify-between">
      <button onClick={() => navigate("/blog-posts")}>
        <IoMdArrowBack size={20} />
      </button>

      <button onClick={handleOpenOutline} className="flex items-center text-sm">
        <IoMdSettings size={20} />
        <p className="hidden sm:block">{t("Settings","Réglages")}</p>
      </button>

      <PostEditorActions />

      {/* <Dialog
        sx={{
          '.MuiBackdrop-root': {
            width: { md: '73%', xs: '100%' },
            top: '40px',
          },

          width: { md: '73%', xs: '100%' },
          top: '20px',
          right: 'unset',
          bottom: 'unset',
          '.MuiDialogContent-root': {
            padding: 0,
          },
          '.MuiPaper-root': {
            borderRadius: 5,
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div
            style={{
              backgroundColor: '#d7d7d7',
              height: '58px',
              width: '100%',
            }}
          />
          <div className='popover-content'>
            <TextField
              id='outlined-basic'
              label='Mandatory'
              variant='outlined'
              value={mandatoryField ?? ''}
              required
              onChange={(e) => {
                setMandatoryField(e.target.value);
              }}
            />
            <TextField
              id='outlined-basic'
              label='Optional'
              variant='outlined'
              value={optionalField ?? ''}
              onChange={(e) => {
                setOptionalField(e.target.value);
              }}
            />
            <Button onClick={handleClose}>Close Settings</Button>
          </div>
        </DialogContent>
      </Dialog> */}

      {/* <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom' }}
        open={open}
        slotProps={{
          paper: {
            className: 'popover-custom',
          },
        }}
      >
        <div className='popover-content'>
          <TextField
            id='outlined-basic'
            label='Mandatory'
            variant='outlined'
            value={mandatoryField}
            required
            onChange={(e) => {
              setMandatoryField(e.target.value);
            }}
          />
          <TextField
            id='outlined-basic'
            label='Optional'
            variant='outlined'
            value={optionalField}
            onChange={(e) => {
              setOptionalField(e.target.value);
            }}
          />
          <Button onClick={handleClose}>Close Settings</Button>
        </div>
      </Popover> */}
    </div>
  );
};

export default SettingsPopover;

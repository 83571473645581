import { apiSlice } from "./apiSlice";
import { ENDPOINTS } from "../../shared/constants/urls";

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSubscription: builder.query({
      query: () => ({
        url: `${ENDPOINTS.CURRENT_SUBSCRIPTION}`,
        method: "GET"
      }),
      extraOptions: { requiresAuth: true },
    }),
    changeSubscription: builder.mutation({
      query: ({ new_plan_id }) => ({
        url: `${ENDPOINTS.CHANGE_SUBSCRIPTION}?new_plan_id=${new_plan_id}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
    cancelSubscription: builder.mutation({
      query: () => ({
        url: `${ENDPOINTS.CANCEL_SUBSCRIPTION}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getPaymentHistory: builder.query({
      query: () => ({
        url: `${ENDPOINTS.PAYMENT_HISTORY}`,
        method: "GET"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getPaymentMethods: builder.query({
      query: () => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}s`,
        method: "GET"
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateDefaultPaymentMethod: builder.mutation({
      query: ({ payment_method_id }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}/update-default?payment_method_id=${payment_method_id}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
    deletePaymentMethod: builder.mutation({
      query: ({ payment_method_id }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}/delete?payment_method_id=${payment_method_id}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
    addPaymentMethod: builder.mutation({
      query: ({ payment_method_id }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}/add?payment_method_id=${payment_method_id}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    })
  }),
});

export const {
  useGetCurrentSubscriptionQuery,
  useChangeSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetPaymentHistoryQuery,
  useGetPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useUpdateDefaultPaymentMethodMutation
} = paymentApiSlice;

import { useTranslation } from 'react-i18next';
import { Check, Close } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import GlobalTextField from '../../FeaturesTemplate/FeaturesFields/GlobalTextField';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { dispatch } from '../../../../../../redux/store';
import { setIsPanelOpen } from '../../../../../../redux/slices/panelSlice';
import GlobalNumberField from '../../FeaturesTemplate/FeaturesFields/GlobalNumberField';
import { globalValidationConfig } from '../formik-function/helpers';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import {
  useGenerateMetaDescriptionMutation,
  useGenerateMetaTitleMutation,
} from '../../../../../../redux/slices/featuresApiSlice';
import { BsMagic } from 'react-icons/bs';
import { MdOutlineQueryStats } from 'react-icons/md';
import { LaunchAnalysisBtn } from '../../Others';

const Metadata = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const [generateMetaTitle, { isLoading: isTitleLoading }] =
    useGenerateMetaTitleMutation();
  const [generateMetaDescription, { isLoading: isDescriptionLoading }] =
    useGenerateMetaDescriptionMutation();
  const formikProps = useFormikContext();
  const [open, setOpen] = useState(false);

  const seoInput =
    formikProps.values.seo_request === ''
      ? 'REQUEST'
      : formikProps.values.seo_request;

  const urlPath = formikProps.values.url_path.replace(
    /(https?:\/\/[^/]+)\/(.+)/,
    (match, p1, p2) => {
      return p1 + ' > ' + p2.replace(/\//g, ' > ');
    }
  );

  const urlSlugValue = `${urlPath} > ${formikProps.values.url_slug}`;

  const urlSlug =
    urlSlugValue.length > 70
      ? urlSlugValue.slice(0, 66) + ' ...'
      : urlSlugValue;

  const metaTitle =
    formikProps.values.meta_title.length > 70
      ? formikProps.values.meta_title.slice(0, 66) + ' ...'
      : formikProps.values.meta_title;

  const metaDesc =
    formikProps.values.meta_description.length > 155
      ? formikProps.values.meta_description.slice(0, 151) + ' ...'
      : formikProps.values.meta_description;

  const isPanelOpen = useSelector((state) => state.panelSlice.isPanelOpen);

  const handleOpen = () => {
    if (!isPanelOpen) {
      setOpen(true);
      dispatch(setIsPanelOpen(true));
    }
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(setIsPanelOpen(false));
  };

  const handleGenerateMetaTitle = async () => {
    try {
      const payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        h1_tag: formikProps.values.h1_title,
        nb_outputs: formikProps.values.nb_meta_title,
        select_content_type: 'Article de blog', // This is just an additional value to add that must ALWAYS be simply "post"
      };
      if (formikProps.values.meta_title_ideas.length !== 0) {
        payload['outputs'] = formikProps.values.meta_title_ideas;
      }
      console.log('meta title generation payload: ', payload);
      const res = await generateMetaTitle(payload).unwrap();
      // const res = {
      //   data: [
      //     'Observer animaux sauvages en France : Guide complet des sites',
      //     'Où observer animaux sauvages en France ? Astuces et lieux clés',
      //     'Guide pour observer animaux sauvages en France : Top spots',
      //   ],
      //   status: 200,
      // };
      if (res?.status !== 200) {
        toast.error('Error occurred while generating meta title');
        return;
      }
      console.log('meta title generation res: ', res);
      formikProps.setFieldValue('meta_title_ideas', res?.data);
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else {
        toast.error(t("something-went-wrong", "Une erreur s'est produite"))
      }
      console.log(error);
    }
  };

  const handleGenerateMetaDescription = async () => {
    try {
      const payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        meta_title: formikProps.values.meta_title,
        nb_outputs: formikProps.values.nb_meta_description,
        select_content_type: 'Article de blog', // This is just an additional value to add that must ALWAYS be simply "post"
      };
      if (formikProps.values.meta_description_ideas.length !== 0) {
        payload['outputs'] = formikProps.values.meta_description_ideas;
      }
      console.log('meta desc generation payload: ', payload);
      const res = await generateMetaDescription(payload).unwrap();
      // const res = {
      //   data: [
      //     'Découvre où observer animaux sauvages en France. Bénéfices, techniques et meilleurs sites pour une immersion unique !',
      //     'Explore la faune française ! Guide complet pour observer animaux sauvages, des parcs nationaux aux réserves naturelles.',
      //     "Envie d'observer les animaux sauvages en France ? Découvre les meilleures zones et conseils pratiques pour une expérience inoubliable !",
      //   ],
      //   status: 200,
      // };
      if (res?.status !== 200) {
        toast.error('Error occurred while generating meta description');
        return;
      }
      console.log('meta desc generation res: ', res);
      formikProps.setFieldValue('meta_description_ideas', res?.data);
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else {
        toast.error(t("something-went-wrong", "Une erreur s'est produite"))
      }
      console.log(error);
    }
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: 'fixed',
            overflow: { md: 'unset', xs: 'scroll' },
            height: { md: '100vh', xs: '100%' },
            width: 'auto',
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            mt: { md: '40px !important', xs: 'unset !important' },
            right: 0,
            top: 0,
            bgcolor: '#000000a1',
          }}
        >
          <Box
            sx={{
              width: { md: 'calc(100vw - 350px)', xs: '100%' },
              display: 'flex',
              height: 'auto',
              justifyContent: 'center',
              overflow: { md: 'auto', xs: 'unset' },
              //scrollbarWidth: "none",
            }}
          >
            <Box
              sx={{
                height: 'fit-content',
                bgcolor: 'white',
                maxWidth: '900px',
                width: '95%',
                my: 6,
                overflow: 'hidden',
                borderRadius: 4,
              }}
            >
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  position: 'relative',
                  borderBottom: '3px solid #e5e7eb',
                }}
              >
                <p className="font-semibold text-lg">
                  {t('Metadatas', 'Métadonnées')}
                </p>
                <Box
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: 0,
                    top: 0,
                    p: 1,
                  }}
                >
                  <Close sx={{ color: 'black' }} />
                </Box>
              </Box>
              <div className="p-6">
                <div className="mb-4 pb-4 px-0 lg:px-32 border-b-[3px] border-slate-200">
                  <div className="flex items-center gap-4">
                    <div className="h-10 w-10 bg-slate-300 flex-shrink-0 rounded-full" />
                    <div className="text-slate-600">
                      <p>{t('Your-website', 'Votre Site Web')}</p>
                      <p>{urlSlug}</p>
                    </div>
                  </div>
                  <div>
                    <p className="text-lg text-blue-800 font-normal">
                      {metaTitle}
                    </p>
                    <p className="text-slate-600 text-sm">{metaDesc}</p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-8 ">
                  <div className="flex-1">
                    <p>{t('Meta-title', 'Méta-titres')}</p>
                    <GlobalTextField
                      style={{ bgcolor: 'white' }}
                      formikProps={formikProps}
                      name={'meta_title'}
                      placeholder={t('Your-meta-title', 'Votre méta-titre ...')}
                      isRequired={globalValidationConfig.meta_title.max}
                      maxLength={globalValidationConfig.meta_title.max}
                    />

                    <div className="flex">
                      <LoadingButton
                        startIcon={<BsMagic />}
                        loading={isTitleLoading}
                        onClick={handleGenerateMetaTitle}
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          height: '100%',
                          mt: 2,
                          bgcolor: '#8b5cf6',
                        }}
                      >
                        {t(
                          'Generate-meta-title',
                          'Générer des idées de méta-titres'
                        )}
                      </LoadingButton>

                      <GlobalNumberField
                        style={{
                          bgcolor: 'white',
                          borderRadius: 'none !important',
                        }}
                        size="small"
                        formikProps={formikProps}
                        name={'nb_meta_title'}
                        isRequired={
                          globalValidationConfig.nb_meta_title.required
                        }
                        minValue={globalValidationConfig.nb_meta_title.min}
                        maxValue={globalValidationConfig.nb_meta_title.max}
                      />
                    </div>

                    <div className="flex flex-col max-h-[270px] overflow-auto mt-2 pr-2 gap-4">
                      {formikProps.values.meta_title_ideas?.map(
                        (item, index) => (
                          <div className="flex flex-col">
                            <div className="ml-auto">
                              <button
                                onClick={() =>
                                  formikProps.setFieldValue('meta_title', item)
                                }
                              >
                                <Check />
                              </button>
                              <button
                                onClick={() =>
                                  formikProps.setFieldValue(
                                    'meta_title_ideas',
                                    formikProps.values.meta_title_ideas.filter(
                                      (i) => i !== item
                                    )
                                  )
                                }
                              >
                                <Close />
                              </button>
                            </div>
                            <div className="bg-neutral-200 font-semibold p-4 rounded-md">
                              {item}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="flex-1">
                    <p>{t('Meta-description', 'Méta-description')}</p>
                    <GlobalTextField
                      style={{ bgcolor: 'white' }}
                      formikProps={formikProps}
                      name={'meta_description'}
                      placeholder={t(
                        'Your-meta-description',
                        'Votre méta-description ...'
                      )}
                      isRequired={
                        globalValidationConfig.meta_description.required
                      }
                      maxLength={globalValidationConfig.meta_description.max}
                    />
                    <div className="flex">
                      <LoadingButton
                        startIcon={<BsMagic />}
                        loading={isDescriptionLoading}
                        onClick={handleGenerateMetaDescription}
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          height: '100%',
                          mt: 2,
                          bgcolor: '#8b5cf6',
                        }}
                      >
                        {t(
                          'Generate-meta-descriptions',
                          'Générer des idées de méta-descriptions'
                        )}
                      </LoadingButton>

                      <GlobalNumberField
                        style={{
                          bgcolor: 'white',
                          borderRadius: 'none !important',
                        }}
                        size="small"
                        formikProps={formikProps}
                        name={'nb_meta_description'}
                        isRequired={
                          globalValidationConfig.nb_meta_description.required
                        }
                        minValue={
                          globalValidationConfig.nb_meta_description.min
                        }
                        maxValue={
                          globalValidationConfig.nb_meta_description.max
                        }
                      />
                    </div>

                    <div className="flex flex-col max-h-[270px] overflow-auto mt-2 pr-2 gap-4">
                      {formikProps?.values?.meta_description_ideas?.map(
                        (item, index) => (
                          <div className="flex flex-col">
                            <div className="ml-auto">
                              <button
                                onClick={() =>
                                  formikProps.setFieldValue(
                                    'meta_description',
                                    item
                                  )
                                }
                              >
                                <Check />
                              </button>
                              <button
                                onClick={() =>
                                  formikProps.setFieldValue(
                                    'meta_description_ideas',
                                    formikProps.values.meta_description_ideas.filter(
                                      (i) => i !== item
                                    )
                                  )
                                }
                              >
                                <Close />
                              </button>
                            </div>
                            <div className="bg-neutral-200 font-semibold p-4 rounded-md">
                              {item}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: '#303539',
              height: '100vh',
              width: { md: '350px', xs: '100%' },
              color: 'white',
              pb: 5,
              //overflow: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: '#8d5064',
                p: 2,
                position: 'relative',
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: 0,
                  top: 0,
                  p: 1,
                }}
              >
                <Close sx={{ color: 'white' }} />
              </Box>
              <h1 className="text-2xl mb-2">{t('Metadatas', 'Métadonnées')}</h1>

              <p className="text-xs mb-2">
                {t(
                  'tutorial-text-metadatas',
                  "Si les métadonnées ne sont pas prises en compte en tant que tel pour le référencement, ce sont elles qui attirent vos lecteurs à cliquer sur vos contenus depuis la SERP. Suivez nos indications, trouvez de l'inspiration et comparez-vous à vos concurrents !"
                )}
              </p>

              <p className="text-xs">
                {t('For-more-info:', "Pour plus d'infos :")}{' '}
                <span>
                  <a className="text-blue-500 underline cursor-pointer">
                    {
                      ('metadatas-optimization-guide',
                      'Métadonnées optimisé pour le SEO : le Guide Complet 2024')
                    }
                  </a>
                </span>
              </p>
            </Box>

            <LaunchAnalysisBtn
              userInfo={userInfo}
              formikProps={formikProps}
              disabled={true}
            />
          </Box>
        </Box>
      ) : (
        <div onClick={handleOpen} className="panel-button">
          {t('Metadatas', 'Métadonnées')}
        </div>
      )}
    </>
  );
};

export default Metadata;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  TextField,
  Button,
} from '@mui/material';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Formik } from 'formik';
import { LinkOpenAIInitialValues, LinkOpenAISchema } from './helper';
import { ClipLoader } from 'react-spinners';
import {
  useLinkOpenAIMutation,
  useUnlinkOpenAIMutation,
} from '../../redux/slices/profileApiSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../../redux/slices/authSlice';
import { InputProfile } from '../../shared/components/profile';

const LinkOpenAI = () => {
  const { t } = useTranslation();
  const [linkOpenAI, { isLoading }] = useLinkOpenAIMutation();
  const [unlinkOpenAI, { isLoading: isUnlinking }] = useUnlinkOpenAIMutation();
  const dispatch = useDispatch();
  const { userInfo, accessToken } = useSelector((state) => state?.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  console.log(userInfo);

  const handleDeleteIntegration = async (props) => {
    try {
      const res = await unlinkOpenAI();
      if (res?.data?.status === 200) {
        const updatedUserInfo = {
          ...userInfo,
          openai_organization: null,
          openai_api_key: null,
        };
        dispatch(
          setCredentials({
            accessToken: accessToken,
            userInfo: updatedUserInfo,
          })
        );
        toast.success('Intégration OpenAI supprimée !');
        props.resetForm();
      }
    } catch (error) {
      toast.error(error?.data?.detail || 'Something went wrong');
    }
  };

  const handleLinkOpenAI = async (values, props) => {
    try {
      const res = await linkOpenAI(values);
      if (res?.data?.status === 200) {
        console.log(res?.data?.data?.openai_api_key)
        const updatedUserInfo = {
          ...userInfo,
          // openai_organization: res?.data?.data?.openai_organization,
          openai_organization: "",
          openai_api_key: res?.data?.data?.openai_api_key,
        };
        dispatch(
          setCredentials({
            accessToken: accessToken,
            userInfo: updatedUserInfo,
          })
        );
        props.setFieldValue("openai_api_key", res?.data?.data?.openai_api_key);
        toast.success('Votre compte OpenAI est connecté !');
        setIsModalOpen(false);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.data?.detail || 'Something went wrong');
    }
  };

  return (
    <>
      <Formik
        initialValues={LinkOpenAIInitialValues}
        enableReinitialize={true}
        validationSchema={LinkOpenAISchema}
        onSubmit={(values, actions) => handleLinkOpenAI(values, actions)}
      >
        {(props) => (
          <div className='mx-auto h-[70vh] flex items-center justify-center bg-white shadow-default rounded-2xl'>
            <form onSubmit={props.handleSubmit} className='w-[630px]'>
              <div className='grid md:grid-cols-2 gap-x-11 gap-5 w-full'>
                {/* <div className='col-span-2'>
                  <InputProfile
                    formikProps={props}
                    type='text'
                    text={t('openai-organization', 'Organisation OpenAI')}
                    name='openai_organization'
                    placeholder={t('openai-organization-format', 'org-...')}
                    disabled={userInfo?.openai_organization ? true : false}
                  />
                </div> */}

                <div className='col-span-2'>
                  <InputProfile
                    formikProps={props}
                    type='text'
                    text={t('openai-api-key', 'Clé API OpenAI')}
                    name='openai_api_key'
                    placeholder={t('openai-api-key-format', 'sk-...')}
                    disabled={userInfo?.openai_api_key ? true : false}
                  />
                </div>

                <div className='col-span-2'>
                  {userInfo?.openai_api_key ? (
                    <div>
                      <button
                        type='button'
                        className='px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light'
                        onClick={() => setIsModalOpen(true)}
                      >
                        {isLoading ? (
                          <ClipLoader size={25} color='white' />
                        ) : (
                          t("Modify-integration", "Modifier l'intégration")
                        )}
                      </button>

                      <button
                        type='button'
                        className='px-5 w-full py-[6px] mt-4 bg-error text-white rounded font-light'
                        onClick={() => handleDeleteIntegration(props)}
                      >
                        {isUnlinking ? (
                          <ClipLoader size={25} color='white' />
                        ) : (
                          t("Delete-integration","Supprimer l'intégration")
                        )}
                      </button>
                    </div>
                  ) : (
                    <button
                      type='submit'
                      className='px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light'
                    >
                      {isLoading ? (
                        <ClipLoader size={25} color='white' />
                      ) : (
                        t('Link-my-openai-account', 'Lier mon compte OpenAI')
                      )}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className='relative z-[999]'
      >
        <DialogBackdrop
          transition
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
        />

        <div className='fixed inset-0 z-[999] w-screen overflow-y-auto bg-black/50'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <DialogPanel
              transition
              className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-2xl sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'
            >
              <div className=''>
                <DialogTitle
                  as='h3'
                  className='text-base font-semibold leading-6 mb-4'
                >
                  Entrez vos nouvelles informations OpenAI
                </DialogTitle>
                <Formik
                  initialValues={userInfo?.openai_api_key || userInfo?.openai_organization
                    ? userInfo
                    : LinkOpenAIInitialValues}
                  enableReinitialize={true}
                  validationSchema={LinkOpenAISchema}
                  onSubmit={handleLinkOpenAI}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <div className='grid md:grid-cols-2 gap-x-11 gap-5 w-full'>
                        {/* <div className='col-span-2'>
                          <InputProfile
                            formikProps={props}
                            type='text'
                            text={t('openai-organization', 'Organisation OpenAI')}
                            name='openai_organization'
                            placeholder={t('openai-organization-format', 'org-...')}
                          />
                        </div> */}

                        <div className='col-span-2'>
                          <InputProfile
                            formikProps={props}
                            type='text'
                            text={t('openai-api-key', 'Clé API OpenAI')}
                            name='openai_api_key'
                            placeholder={t('openai-api-key-format', 'sk-...')}
                          />
                        </div>

                        <div className='col-span-2'>
                          <button
                            type='submit'
                            className='px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light'
                          >
                            {isLoading ? (
                              <ClipLoader size={25} color='white' />
                            ) : (
                              'Lier mon compte OpenAI'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default LinkOpenAI;

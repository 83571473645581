import React from "react";
import { ErrorMessage } from "formik";

const FieldErrorMessage = ({ name }) => {
  return (
    <div style={{ color: "#d32f2f", fontSize: "0.75rem",margin: '3px 0px',display:'flex',justifyContent:'flex-start',paddingLeft:'8px' }}>
      <ErrorMessage name={name} />
    </div>
  );
};

export default FieldErrorMessage;
import { useTranslation } from "react-i18next";
import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../../../redux/store';
import { setIsPanelOpen } from '../../../../../redux/slices/panelSlice';
import { useFormikContext } from 'formik';
import GlobalNumberField from '../FeaturesTemplate/FeaturesFields/GlobalNumberField';
import { globalValidationConfig } from './formik-function/helpers';
import GlobalTextField from '../FeaturesTemplate/FeaturesFields/GlobalTextField';
import {
  useGeneratePostConclusionMutation,
  useGeneratePostIntroductionMutation,
  useGeneratePostPartMutation,
} from '../../../../../redux/slices/featuresApiSlice';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { BsMagic } from 'react-icons/bs';
import { MdOutlineQueryStats } from 'react-icons/md';
import { LaunchAnalysisBtn } from '../Others';

function cleanUpElementToJSON(rootElement) {
  if (!rootElement) return { part_name: '', part: '' };

  // Helper function to remove <p> and <br> tags
  function cleanPartName(element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.querySelectorAll('p, br').forEach((el) => el.remove());
    return clonedElement.outerHTML;
  }

  // Helper function to remove only empty <p> tags
  function cleanPart(element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.querySelectorAll('p').forEach((el) => {
      if (!el.textContent.trim()) el.remove();
    });
    return clonedElement.outerHTML;
  }

  // Generate the part_name and part fields
  const part_name = cleanPartName(rootElement);
  const part = cleanPart(rootElement);

  // Return the result as a JSON object
  return {
    part_name: part_name,
    part: part,
  };
}

const Content = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  
  const [open, setOpen] = useState(false);
  const formikProps = useFormikContext();
  const currentZone = useSelector((state) => state.panelSlice.currentZone);
  const seoInput =
    formikProps.values.seo_request === ''
      ? 'REQUEST'
      : formikProps.values.seo_request;
  const isPanelOpen = useSelector((state) => state.panelSlice.isPanelOpen);

  const handleOpen = () => {
    if (!isPanelOpen) {
      setOpen(true);
      dispatch(setIsPanelOpen(true));
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setIsPanelOpen(false));
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: 'fixed',
            overflow: { md: 'unset', xs: 'scroll' },
            height: { md: '100vh', xs: '100%' },
            width: { md: 'auto', xs: '100%' },
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'end',
            mt: { md: '48px !important', xs: 'unset !important' },
            right: 0,
            top: 0,
          }}
        >
          {/* <Box
            sx={{
              width: { md: 'calc(100vw - 335px)', xs: '100%' },
              display: 'flex',
              height: 'auto',
              justifyContent: 'center',
              overflow: { md: 'scroll', xs: 'unset' },
              scrollbarWidth: 'none',
            }}
          >
            <Box
              sx={{
                height: 'fit-content',
                bgcolor: 'white',
                maxWidth: '900px',
                width: '95%',

                my: 6,
                overflow: 'hidden',
                borderRadius: 1.5,
              }}
            >
              <Box
                sx={{
                  py: 1,
                  px: 3,
                  bgcolor: '#e5e5e5',
                  position: 'relative',
                }}
              >
                <p style={{ fontWeight: 'bold' }}>Settings</p>
                <Box
                  onClick={() => setOpen(false)}
                  sx={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: 0,
                    top: 0,
                    p: 1,
                  }}
                >
                  <Close sx={{ color: 'black' }} />
                </Box>
              </Box>
              <Box sx={{ bgcolor: 'white', px: 3, py: 2 }}>
                <Stack direction={{ md: 'row', xs: 'column' }} spacing={1}>
                  <Box
                    sx={{
                      flex: 1,
                      bgcolor: '#e5e5e5',
                      borderRadius: 1,
                      textAlign: 'center',
                    }}
                  >
                    <p style={{ fontWeight: 'bold' }}>Writting Style</p>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      bgcolor: '#e5e5e5',
                      borderRadius: 1,
                      textAlign: 'center',
                    }}
                  >
                    <p style={{ fontWeight: 'bold' }}>Company / Website</p>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      bgcolor: '#e5e5e5',
                      borderRadius: 1,
                      textAlign: 'center',
                    }}
                  >
                    <p style={{ fontWeight: 'bold' }}>Product</p>
                  </Box>
                </Stack>
                <Grid container spacing={4}>
                  <Grid item lg={6} xs={12}>
                    <p>Subject of the post</p>
                    <TextField
                      size='small'
                      fullWidth
                      multiline={true}
                      rows={5}
                      placeholder='Ex: A detailed form about the rose-ringed parakeet...'
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <p>SEO target request</p>
                    <TextField
                      fullWidth
                      size='small'
                      placeholder='Ex: rose-ringed parakeet...'
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <p>Outline</p>
                    <Box>
                      <p>Number of words</p>
                      <TextField
                        fullWidth
                        size='small'
                        placeholder='Ex: rose-ringed parakeet...'
                      />
                    </Box>
                    <Box>
                      <p>{`Number of big parts (h2)`}</p>
                      <TextField
                        fullWidth
                        size='small'
                        placeholder='Ex: rose-ringed parakeet...'
                      />
                    </Box>
                    <Button
                      variant='contained'
                      sx={{ textTransform: 'none', my: 3 }}
                    >
                      Generate Outline
                    </Button>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Box my={2}>
                      <OutlineSelector />
                    </Box>
                    <TextField
                      rows={15}
                      multiline={true}
                      size='small'
                      fullWidth
                      placeholder='
                      Ex:
                      <h2>Rose-ringed parakeet taxonomy</h2>
                      ___<h3>Classification</h3>
                      ___<h3>Subspecies</h3>
                      <h2>Physical characteristics of the rose-ringed 
                      parakeet</h3>

                      '
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 2,
                    }}
                    lg={6}
                    xs={12}
                  >
                    <p>Include an h2 for</p>
                    <FormControl>
                      <RadioGroup row>
                        <FormControlLabel
                          value=''
                          control={<Radio />}
                          label='Introduction'
                        />
                        <FormControlLabel
                          value=''
                          control={<Radio />}
                          label='Conclusion'
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                    lg={6}
                    xs={12}
                  >
                    <Button sx={{ textTransform: 'none' }} variant='contained'>
                      Start to write the actual oultine
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box> */}
          <Box
            sx={{
              backgroundColor: '#303539',
              height: '100vh',
              width: { md: '350px', xs: '100%' },
              color: 'white',
              pb: 5,
              //overflow: 'auto',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#3e6590',
                padding: 2,
                position: 'relative',
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: 0,
                  top: 0,
                  p: 2,
                }}
              >
                <Close sx={{ color: 'white', fontSize: 32 }} />
              </Box>
              <h1 className="text-2xl mb-2">{t("Content", "Contenu")}</h1>

              <p className="text-xs mb-2">{t("tutorial-text-content","La balise H1 est un important facteur du référencement de votre page, autant par l’importance qu’elle a pour Google que pour vos lecteurs. Suivez nos indications, générez des idées et inspirez-vous de la la concurrence !")}</p>

              <p className="text-xs">
                {t("For-more-info:", "Pour plus d'infos :")}{" "}
                <span>
                  <a className="text-blue-500 underline cursor-pointer">
                    {t("content-optimization-guide", "Rédaction SEO : le Guide Complet 2024")}
                  </a>
                </span>
              </p>
              </Box>

              <LaunchAnalysisBtn userInfo={userInfo} formikProps={formikProps} disabled={true} />
          </Box>
        </Box>
      ) : (
        <div onClick={handleOpen} className="panel-button">
          {t("Content", "Contenu")}
        </div>
      )}
    </>
  );
};

export default Content;

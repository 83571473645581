import React from "react";
import { useTranslation } from "react-i18next";
import { useResendVerificationEmailMutation } from "../../redux/slices/authApiSlice";
import { toast } from "react-toastify";
import { ButtonAuth } from "../../shared/components/auth"

const Confirmation = () => {
  const { t } = useTranslation();
  const [resendVerificationEmail, { isLoading }] = useResendVerificationEmailMutation();

  const handleResendVerificationEmail = async () => {
    try {
      const res = await resendVerificationEmail().unwrap();
      if (res.status_code === 200) {
        toast.info("Un nouvel email de validation a été envoyé !")
      }
    } catch (error) {
      toast.error(error?.data?.detail);
    }
  };

  return (
    <div className="space-y-6">
      
      <p>{t("confirmation-email-sent", "Un email de vérification vous a été envoyé sur votre adresse. Si vous ne l'avez pas reçu d'ici quelques minutes ou si le lien a expiré, clickez ici pour recevoir un nouvel email de vérification.")}</p>

      <ButtonAuth
        action={handleResendVerificationEmail}
        disabled={isLoading}
        isLoading={isLoading}
        type="submit"
        text={t("resend-verification-email", "Renvoyer l'email de vérification")}
      />

    </div>
  );
};

export default Confirmation;

import { useCurrentEditor } from '@tiptap/react';
import TablePopover from '../TablePopover';
import LinkPopover from '../LinkPopover';
import TypographySelect from './TypographySelect';
import './Toolbar.css';
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BlockquoteIcon,
  BoldIcon,
  BulletListIcon,
  ItalicIcon,
  OrderedListIcon,
  RedoIcon,
  StrikeThroughIcon,
  UnderlineIcon,
  UndoIcon,
  UnsetMarksIcon,
} from '../Icons';
import SettingsPopover from '../SettingsPopover';
import ZoneSelector from './ZoneSelector';
import { useEffect, useState } from 'react';
import { isHeadingZone } from '../Zones/HeadingZones/H2Zone/Extension';
import { Box, TextField, useMediaQuery, useTheme } from '@mui/material';
import { dispatch } from '../../../../../redux/store';
import {
  setIsPanelOpen,
  setOpenUrlSlug,
} from '../../../../../redux/slices/panelSlice';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

const Toolbar = ({ editor }) => {
  const [currentZone, setCurrentZone] = useState('h1zone');
  const [headingZone, setHeadingZone] = useState('');
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isPanelOpen = useSelector((state) => state?.panelSlice?.isPanelOpen);

  const { values } = useFormikContext();
  const path =
    values.url_path === '' ? 'https://botblogr/seo' : values.url_path;
  const urlSlug =
    values.url_slug === '' ? 'url-slug-seo-ultimate-guide' : values.url_slug;
  const urlSlugValue = `${path}/${urlSlug}`;

  const handleOpenUrlSlug = () => {
    if (!isPanelOpen) {
      dispatch(setOpenUrlSlug(true));
      dispatch(setIsPanelOpen(true));
    }
  };

  const getCurrentZone = (node) => {
    if (!node) return null;
    if (node?.parent?.name === 'doc') return node.name;
    return getCurrentZone(node.parent);
  };

  useEffect(() => {
    const node = editor.$pos(editor.state.selection.head);
    const zone = getCurrentZone(node);
    const headingZone = isHeadingZone(node)?.name ?? null;
    if (headingZone) {
      setHeadingZone(headingZone);
    } else {
      setHeadingZone('contentzone');
    }
    if (zone) {
      setCurrentZone(zone);
    }
  }, [editor.state.selection.head]);

  return (
    <div className="header-container">
      <SettingsPopover />
      <div className="urlslug-wrapper" onClick={handleOpenUrlSlug}>
        <input
          className="urlslug-input"
          readOnly
          type="text"
          value={urlSlugValue}
        />
      </div>
      <div className="editor-toolbar-container border-[2px] border-[#e2e8f0]">
        {currentZone === 'h1zone' ? <div className="toolbar-blocker" /> : null}

        <TypographySelect editor={editor} />

        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`${
            editor.isActive('bold') ? 'is-active' : ''
          } toolbar-btn`}
        >
          <BoldIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`${
            editor.isActive('italic') ? 'is-active' : ''
          } toolbar-btn`}
        >
          <ItalicIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={`${
            editor.isActive('strike') ? 'is-active' : ''
          } toolbar-btn`}
        >
          <StrikeThroughIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          className={`${
            editor.isActive('underline') ? 'is-active' : 'no-active'
          } toolbar-btn`}
        >
          <UnderlineIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={`${
            editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''
          } toolbar-btn`}
        >
          <AlignLeftIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={`${
            editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
          } toolbar-btn`}
        >
          <AlignCenterIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={`${
            editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''
          } toolbar-btn`}
        >
          <AlignRightIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          className={`${
            editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''
          } toolbar-btn`}
        >
          <AlignJustifyIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="toolbar-btn"
        >
          <UnsetMarksIcon />
        </button>

        <TablePopover editor={editor} editorSection={'content'} />

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`${
            editor.isActive('bulletList') ? 'is-active' : ''
          } toolbar-btn`}
        >
          <BulletListIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`${
            editor.isActive('orderedList') ? 'is-active' : ''
          } toolbar-btn`}
        >
          <OrderedListIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`${
            editor.isActive('blockquote') ? 'is-active' : ''
          } toolbar-btn`}
        >
          <BlockquoteIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="toolbar-btn"
        >
          <UndoIcon />
        </button>

        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="toolbar-btn"
        >
          <RedoIcon />
        </button>

        <LinkPopover editor={editor} editorSection={'content'} />
      </div>
    </div>
  );
};

export default Toolbar;

import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useAddPaymentMethodMutation } from '../../../redux/slices/paymentApiSlice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const AddCardForm = ({refetch_card}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [addPaymentMethod, { isLoading: addPaymentMethodLoading }] = useAddPaymentMethodMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod, error: paymentError } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentError) {
        setError(paymentError.message);
        setLoading(false);
        return;
      }

      const response = await addPaymentMethod({ payment_method_id: paymentMethod.id });

      if (response?.data?.status === 201) {
        setSuccess(true);
        cardElement.clear();
        refetch_card();
      } else {
        setError(toast.error(response?.error?.data?.detail) || 'Something went wrong');
        toast.error(response?.error?.data?.detail || 'Something went wrong');
      }
    } catch (error) {
      setError('Failed to add the card');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full mt-4">
      <div className="mb-4">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#fa755a',
              },
            },
          }}
        />
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">Card added successfully!</div>}
      <button
        type="submit"
        disabled={!stripe || loading}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
      >
        {loading ? 'Processing...' : 'Add Card'}
      </button>
    </form>
  );
};

const AddCard = ({ refetch_card }) => {
  return (
    <Elements stripe={stripePromise}>
      <AddCardForm refetch_card={refetch_card}/>
    </Elements>
  );
};

export default AddCard;

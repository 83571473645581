import { useTranslation } from "react-i18next";
import { Formik } from 'formik';
import { forgotPasswordInitialValues as initialValues, ForgotPasswordSchema as Schema } from './helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useForgotPasswordMutation } from '../../redux/slices/authApiSlice';
import { InputAuth, ButtonAuth } from "../../shared/components/auth";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [forgot, { isLoading }] = useForgotPasswordMutation();
  const navigate = useNavigate();
  
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Schema}
        onSubmit={async (values) => {
          try {
            const res = await forgot(values).unwrap();
            if(res?.message.includes("successful")){
              toast.info("Un email vous a été envoyé sur votre adresse. Cliquez sur le lien et changez votre mot de passe !")
            }
         
          } catch (error) {
            toast?.error(error?.data?.detail);
          }
        }}
      >
        {(props) => (
          <form action="" method="POST" className="space-y-6">
            <InputAuth
              formikProps={props}
              type="text"
              text={t("enter-email", "Entrez votre email")}
              name="email"
              placeholder={t("enter-email", "Entrez votre email")}
            />

            <div className="flex space-x-4">
              <ButtonAuth
                action={props.handleSubmit}
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                text={t("submit", "Envoyer")}
              />

              <ButtonAuth
                action={() => navigate('/login', { replace: true })}
                disabled={false}
                isLoading={false}
                type="submit"
                text={t("cancel", "Annuler")}
                secondary={true}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
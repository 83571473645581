import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import { BsMagic } from 'react-icons/bs';
import { useGeneratePostConclusionMutation } from '../../../../../../redux/slices/featuresApiSlice';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import RewriteModal from '../../SidePanel/global-rewrite-modal/rewrite-modal';
import { globalValidationConfig } from '../../SidePanel/formik-function/helpers';
import GlobalTextField from '../../FeaturesTemplate/FeaturesFields/GlobalTextField';
import { useWebSocket } from "../../WebSocketContext";
import { toast } from 'react-toastify';

const ConclusionModal = ({ setOpen }) => {
  const { t } = useTranslation();
  const [generatePostConclusion, { isLoading }] =
    useGeneratePostConclusionMutation();
  const formikProps = useFormikContext();

  const {
    concluHeading,
    setOpenConclusionModal,
    openConclusionRewrite,
    setOpenConclusionRewrite,
    bufferRef
  } = useWebSocket()

  const handleGenerate = async () => {
    try {
      let payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        length: formikProps.values.conclusion_length,
        transition_article: formikProps.values.transition_article,
      };
      if (formikProps.values.conclusion.includes("<p>")) {
        console.log("IT IS REWRITE CASE")
        payload['conclusion'] = formikProps.values.conclusion;
      } else {
        concluHeading.current = formikProps.values.conclusion;
      }

      console.log(`conclusion generation payload: `, payload);

      const res = await generatePostConclusion({...payload, ...{ stream: true }}).unwrap();

      if (res?.status !== 200) {
        toast.error('Error occurred while generating introduction');
        return;
      }

      console.log(`conclusion generation res: `, res);

      if (!formikProps.values.conclusion.includes("<p>")) {
        const zone = document.getElementById('conclusionzone');
        if (zone) {
          zone.innerHTML = res?.data;
        }
      } else {
        console.log("IT IS REWRITING CASE")
        formikProps.setFieldValue('conclusion_rewrite', res?.data);
      }

      concluHeading.current = "";
      bufferRef.current = "";
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else {
        toast.error(t("something-went-wrong", "Une erreur s'est produite"))
      }
      console.log(error);
    }
  };

  const saveSelectedVersion = (data) => {
    const zone = document.getElementById('conclusionzone');
    if (zone) {
      zone.innerHTML = data;
    }
    formikProps.setFieldValue('conclusion_rewrite', '');
    setOpenConclusionRewrite(false);
    setOpenConclusionModal(false);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        //overflow: { md: 'unset', xs: 'scroll' },
        height: '100vh',
        width: { md: 'calc(100vw - 350px)', xs: '100%' },
        display: 'flex',
        mt: { md: '48px !important', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 99999,
      }}
    >
      {openConclusionRewrite ? (
        <RewriteModal
          previous={formikProps.values.conclusion}
          rewritten={formikProps.values.conclusion_rewrite}
          save={saveSelectedVersion}
          label={t('Conclusion-Rewrite', 'Conclusion (réécriture)')}
          setOpenModal={setOpenConclusionRewrite}
        />
      ) : (
        <div className="m-auto bg-white rounded-xl w-[80%] max-w-[500px]">
          <div className="flex justify-between p-4 items-center border-b-[3px] border-slate-200">
            <p>
              {formikProps.values.conclusion === ''
                ? t('Conclusion', 'Conclusion')
                : t('Conclusion-Rewrite', 'Conclusion (réécriture)')}
            </p>
            <Close
              onClick={() => setOpen(false)}
              className="cursor-pointer"
              sx={{ fontSize: 32 }}
            />
          </div>
          <div className="p-4">
            <FormControl sx={{ mb: 2 }} fullWidth>
              <p className="mb-2">
                {t('Generate-conclusion', 'Générer une conclusion')}
              </p>
              <Select
                sx={{ bgcolor: 'white' }}
                value={formikProps.values.conclusion_length}
                onChange={(e) =>
                  formikProps.setFieldValue('conclusion_length', e.target.value)
                }
              >
                <MenuItem value={'courte'}>{t('Short', 'Courte')}</MenuItem>
                <MenuItem value={'moyenne'}>{t('Medium', 'Moyenne')}</MenuItem>
                <MenuItem value={'longue'}>{t('Long', 'Longue')}</MenuItem>
              </Select>
            </FormControl>

            <div>
              <p>
                {t(
                  'Transition-article-for-conclusion',
                  'Article de transition pour la conclusion'
                )}
              </p>
              <GlobalTextField
                style={{ bgcolor: 'white' }}
                formikProps={formikProps}
                name={'transition_article'}
                placeholder={
                  'Ex: ' +
                  t(
                    'placeholder-transition-article',
                    "un article de blog sur les cocons sémantiques...'"
                  )
                }
                isRequired={globalValidationConfig.transition_article.required}
                maxLength={globalValidationConfig.transition_article.max}
              />
            </div>

            <div className="flex items-center gap-4">
              <LoadingButton
                startIcon={<BsMagic />}
                loading={isLoading}
                onClick={handleGenerate}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  bgcolor: '#8b5cf6',
                }}
              >
                {t('Generate-conclusion', 'Générer une conclusion')}
              </LoadingButton>

              {formikProps.values.conclusion_rewrite !== '' ? (
                <Button variant="contained" onClick={() => setOpenConclusionRewrite(true)}>
                  see rewritten
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ConclusionModal;

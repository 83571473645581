import InputProfile from "./inputProfile";
import { useState } from "react";

const SelectProfile = ({ 
    formikProps,
    options,
    text, 
    name
}) => {
    // const [showCustomInput, setShowCustomInput] = useState(false);

    // const handleSelectChange = (e) => {
    //     if (name === "tone") {
    //         const selectedValue = e.target.value;
    //         formikProps.handleChange(e);
    //         setShowCustomInput(selectedValue === "Personnalisé");
    //     }
    // };

    return (
        <div>
            <label
                htmlFor={name}
                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
            >
                {text}
            </label>
            <select
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                id={name}
                name={name}
                // onChange={handleSelectChange}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values[name]}
            >
                {options?.map((option) => (
                    <option key={option.name} value={option.name}>
                        {option.label}
                    </option>
                ))}
            </select>

            {/* {showCustomInput && (
                <InputProfile
                    formikProps={formikProps}
                    type="text"
                    text="Entrez un ton personnalisé"
                    name="customTone"
                    placeholder="Votre ton personnalisé"
                    maxLength={50}
                    multiline={false}
                />
            )} */}
        </div>
    );
};

export default SelectProfile;
import React from 'react';
import { useTranslation } from "react-i18next";
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from "react-redux";
import { useChangeSubscriptionMutation } from '../../../redux/slices/paymentApiSlice';

const SelectPlan = ({
    newPlan,
    currentSubscription,
    refetchCurrentSub,
    returnToOptions
}) => {
    const { t } = useTranslation();

    const STANDARD_PRICE_ID = process.env.REACT_APP_MONTHLY_STRIPE_STANDARD_ID;
    const ANNUAL_STANDARD_PRICE_ID = process.env.REACT_APP_ANNUAL_STRIPE_STANDARD_ID;

    const PREMIUM_PRICE_ID = process.env.REACT_APP_MONTHLY_STRIPE_PREMIUM_ID;
    const ANNUAL_PREMIUM_PRICE_ID = process.env.REACT_APP_ANNUAL_STRIPE_PREMIUM_ID;

    const ULTIMATE_PRICE_ID = process.env.REACT_APP_MONTHLY_STRIPE_ULTIMATE_ID;
    const ANNUAL_ULTIMATE_PRICE_ID = process.env.REACT_APP_ANNUAL_STRIPE_ULTIMATE_ID;

    const [changeSubscription, { isLoading: changeSubscriptionLoading }] = useChangeSubscriptionMutation();

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

    const subscriptions = {
        [STANDARD_PRICE_ID]: {
            "name": "Standard",
            "period": "monthly",
            "price": "34,90",
            "cost-today": "1,07"
        },
        [ANNUAL_STANDARD_PRICE_ID]: {
            "name": "Standard",
            "period": "annual",
            "price": "349.00",
            "cost-today": "1,07"
        },
        [PREMIUM_PRICE_ID]: {
            "name": "Premium",
            "period": "monthly",
            "price": "49,90",
            "cost-today": "1,07"
        },
        [ANNUAL_PREMIUM_PRICE_ID]: {
            "name": "Premium",
            "period": "annual",
            "price": "499.00",
            "cost-today": "1,07"
        },
        [ULTIMATE_PRICE_ID]: {
            "name": "Ultimate",
            "period": "monthly",
            "price": "99,90",
            "cost-today": "1,07"
        },
        [ANNUAL_ULTIMATE_PRICE_ID]: {
            "name": "Ultimate",
            "period": "monthly",
            "price": "999.00",
            "cost-today": "1,07"
        },
    }

    const default_credit_card = "Visa **** 4242"

    const handleSubscriptionChange = async () => {
        const response= await changeSubscription({ newPlan });
        if (response?.data?.status === 200) {
          toast.success(response?.data?.message)
          refetchCurrentSub();
        }else{
          toast.error(response?.error?.data?.detail)
        };
    };

    return (
        <div className="flex justify-center items-center bg-gray-50">
            <div className="bg-white rounded-lg pt-6 w-full">
    
            <div className="mb-4">
                <h3 className="text-lg font-semibold">{currentSubscription.subscription.name}</h3>
                <p className="text-sm text-gray-600">{t("What-you-will-pay-every", "Ce que vous paierez tous les")} {subscriptions[newPlan]["period"] === "monthly" ? t("months","mois") : t("years","ans")} {t("starting-from", "à compter du")}</p>
                <p className="text-sm text-gray-600">{new Date(currentSubscription.subscription.nex_payment_date*1000).toLocaleDateString('fr-FR')}</p>
                <p className="text-2xl font-bold mt-2">{subscriptions[newPlan]["price"]} €</p>
            </div>
    
            <div className="mb-4 rounded-lg bg-gray_local-400/10 p-3">
                <div className="flex justify-between items-center">
                <p className="text-gray-600">{t("Amount-due-today", "Montant dû aujourd'hui")}</p>
                <p className="text-xl font-bold">{subscriptions[newPlan]["cost-today"]} €</p>
                </div>
                <p className="text-sm text-gray-500">{default_credit_card}</p>
            </div>
    
            <div className="mb-4">
                <button className="text-blue-500 text-sm">{t("Show-details", "Afficher les détails")}</button>
            </div>
    
            <div className="mb-6">
                <button className="text-blue-500 text-sm">{t("Add-promo-code", "Ajouter le code promotionnel")}</button>
            </div>

            <div className="flex max-md:justify-center space-x-2">
                <button onClick={()=>handleSubscriptionChange(newPlan)} className="bg-blue-50 hover:bg-blue-100 text-white px-4 py-2 rounded">
                    {changeSubscriptionLoading ? <ClipLoader color="white"/> : t("Subscribe-and-pay", "S'abonner et payer")}
                </button>
                
                <button onClick={returnToOptions} className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white px-4 py-2 rounded">
                    {t("Return", "Retour")}
                </button>
            </div>
            </div>
        </div>
    );
}

export default SelectPlan;
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaCcVisa, FaCcMastercard, FaCcPaypal } from 'react-icons/fa';
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { toast } from 'react-toastify';
import { CustomLoader } from '../../shared/components/others';
import {
    useGetCurrentSubscriptionQuery,
    useGetPaymentHistoryQuery,
    useGetPaymentMethodsQuery,
    useDeletePaymentMethodMutation,
    useUpdateDefaultPaymentMethodMutation
} from '../../redux/slices/paymentApiSlice';
import PlansOptions from '../../shared/components/payment/plansOptions';
import SelectPlan from '../../shared/components/payment/selectPlan';
import CancelPlan from '../../shared/components/payment/cancelPlan';
import AddCard from '../../shared/components/payment/addCardForm';

const Subscription = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.auth.userInfo);

    const [open, setOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const handleModal = async (modal_type=null) => {
        if (modal_type) {
            setModalType(modal_type);
            setOpen(!open);
        } else {
            setOpen(!open);
        }
    };

    // Invoices
    const { data: invoicesList, isLoading: invoicesLoading } = useGetPaymentHistoryQuery();

    // Dropdown
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownRefs = useRef([]);
    const clickTimeout = useRef(null);
    const toggleDropdown = (index) => {
        clearTimeout(clickTimeout.current);
        if (activeDropdown === index) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(index);
        }
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (activeDropdown !== null && dropdownRefs.current[activeDropdown] && !dropdownRefs.current[activeDropdown].contains(event.target)) {
                // Use a short timeout to avoid conflicts when clicking the button again
                clickTimeout.current = setTimeout(() => {
                    setActiveDropdown(null);
                }, 100);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            clearTimeout(clickTimeout.current);  // Cleanup timeout on component unmount
        };
    }, [activeDropdown]);
    
    // Payment methods
    const { data: paymentMethodsList, isLoading: paymentMethodsLoading, refetch } = useGetPaymentMethodsQuery();
    const [deletePaymentMethod, { isLoading: deletePaymentMethodLoading }] = useDeletePaymentMethodMutation();
    const [updateDefaultPaymentMethod, { isLoading: updateDefaultPaymentMethodLoading }] = useUpdateDefaultPaymentMethodMutation();

    const handleUpdateDefaultPaymentMethod = async (payment_method_id) => {
        const response= await updateDefaultPaymentMethod({payment_method_id});
        if(response?.data?.status===201){
          toast.success(response?.data?.message)
          refetch();
        }else{
          toast.error(response?.error?.data?.detail)
        };
    };

    const handleDeletePaymentMethod = async (payment_method_id) => {
        const response= await deletePaymentMethod({payment_method_id});
        if(response?.data?.status===200){
          toast.success(response?.data?.message);
          refetch();
        }else{
          toast.error(response?.error?.data?.detail)
        };
    };

    const paymentMethodIcons = {
        "visa": {"icon": <FaCcVisa />, "name": "Visa"},
        "mastercard": {"icon": <FaCcMastercard />, "name": "Mastercard"},
        "paypal": {"icon": <FaCcPaypal />, "name": "PayPal"}
    };

    // Subscriptions
    const [newSubscription, setNewSubscription] = useState(null);
    const { data: currentSubscription, isLoading: currentSubLoading, refetch: currentSubRefetch } = useGetCurrentSubscriptionQuery();
    const handleNewSubscriptionSelection = async (new_sub) => {
        setNewSubscription(new_sub);
        setModalType("validate-change");
    }

    if (!userInfo.plan) {
        return (
            <div className="mb-5 mx-auto flex flex-col bg-white shadow-default rounded-2xl">
                <div className="w-full p-6">
                    <div className="flex max-md:justify-center space-x-2">
                        <p>{t("You-must-choose-a-plan-before-managing-you-subscription", "Vous devez d'abord choisir une formule avant de pouvoir gérer vos abonnements.")}</p>
                        <button onClick={()=>navigate("/pricing")}className="bg-blue-50 hover:bg-blue-100 text-white px-4 py-2 rounded">{t("Choose-a-plan", "Choisir un abonnement")}</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="sm:flex sm:space-x-4">
            
            <div className="sm:w-1/2 mb-5 mx-auto flex flex-col bg-white shadow-default rounded-2xl">
                <div className="w-full p-6">
                    <h2 className="text-xl font-semibold mb-5">{t("CURRENT-SUBSCRIPTION", "ABONNEMENT ACTUEL")}</h2>
                    {userInfo.plan === "Demo" ?
                        <div className="pb-2">
                            <h3 className="text-xl font-semibold">{t("Demo", "Démo")}</h3>
                        </div>
                    :
                        <div className="pb-2">
                            {currentSubLoading ?
                                <div className="flex justify-center items-center h-[65vh]">
                                    <CustomLoader />
                                </div>
                                :
                                <div className="justify-between items-center">
                                    <div className='space-y-3'>
                                        <div>
                                            <h3 className="text-xl font-semibold">{currentSubscription.subscription.name}</h3>
                                            <p className="text-3xl font-bold">{currentSubscription.subscription.price.toFixed(2)} € <span className="text-lg font-normal">{currentSubscription.subscription.period === "annual" ? t("per-year", "par an") : t("per-month", "par mois")}</span></p>
                                        </div>
                                        <p className="text-md text-gray-600">{t("Your-subscription-will-be-renewed-on", "Votre abonnement sera renouvelé le ")} {new Date(currentSubscription.subscription.nex_payment_date*1000).toLocaleDateString('fr-FR')}.</p>
                                        
                                        <div className="flex items-center mt-2 space-x-1">
                                            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Visa_Logo.png" alt="Visa" className="h-5 mr-2" /> */}
                                            {paymentMethodIcons[currentSubscription.subscription.payment_method.type].icon}
                                            <p className="text-md">{paymentMethodIcons[currentSubscription.subscription.payment_method.type].name} {currentSubscription.subscription.payment_method.card_number} <span className="text-sm">({currentSubscription.subscription.payment_method.expiry_date})</span></p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-6 space-x-2">
                                        <button onClick={()=>handleModal("modify")} className="bg-blue-50 hover:bg-blue-100 text-white px-4 py-2 rounded min-h-[4rem] leading-tight whitespace-normal">{t("change-subscription", "Changer d'abonnement")}</button>
                                        <button onClick={()=>handleModal("cancel")} className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white px-4 py-2 rounded min-h-[4rem] leading-tight whitespace-normal">{t("cancel-subscription", "Annuler l'abonnement")}</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className='sm:w-1/2'>

                <div className="p-6 mb-5 mx-auto flex flex-col bg-white shadow-default rounded-2xl">
                    <h2 className="text-xl font-semibold mb-4">{t("PAYMENT-METHODS", "MOYENS DE PAIEMENT")}</h2>
                    
                    {paymentMethodsLoading ?
                        <div className="flex justify-center items-center h-[65vh]">
                            <CustomLoader />
                        </div>
                        :
                        <div className="space-y-4 mb-4">
                            {
                                (!paymentMethodsList?.paymentMethodsList || paymentMethodsList.paymentMethodsList.length === 0) ?
                                <p>{t("no-payment-method-yet", "Aucun mode de paiement n'a été ajouté pour le moment.")}</p>
                                :
                                (paymentMethodsList.payment_methods?.map((card, index) =>  (
                                    <div key={`card-${index}`} className="bg-gray_local-400/10 rounded-lg p-3 flex justify-between items-start xl:items-center">
                                        <div className="flex flex-col xl:flex-row xl:items-center">
                                            <div className="flex items-center space-x-1">
                                                {paymentMethodIcons[card['type']].icon}
                                                <p className="text-md relative">
                                                    {paymentMethodIcons[card['type']].name} {card['card-number']}
                                                    {card["default"] && (
                                                        <span className="absolute ml-2 py-1 px-1 text-xs bg-gray_local-400/50 rounded-md tracking-wide">
                                                            {t("Active","Actif")}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
    
                                            {/* Expiry date: Shown next to Visa number on larger screens */}
                                            <p className="text-sm text-gray-600 xl:hidden">
                                                {t("expires-on", "Expire le")} {card['expiry-date']}
                                            </p>
                                        </div>
    
                                        {/* Right column: Expiry date on smaller screens, button always on the right */}
                                        <div className="flex flex-col items-end space-y-2 xl:flex-row xl:items-center xl:space-x-4">
                                            <p className="text-sm text-gray-600 hidden xl:block">
                                                {t("expires-on", "Expire le")} {card['expiry-date']}
                                            </p>
    
                                            {/* 3-dot button */}
                                            <div className="relative flex-shrink-0">
                                                <button onClick={() => toggleDropdown(index)} className="text-gray-600">
                                                    •••
                                                </button>
    
                                                {activeDropdown === index && (
                                                    <div
                                                        ref={(el) => (dropdownRefs.current[index] = el)}
                                                        className="absolute right-0 top-full mt-2 bg-white rounded-lg shadow-lg p-2 z-10 w-80"
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                handleUpdateDefaultPaymentMethod(card["id"]);
                                                                setActiveDropdown(null);
                                                            }}
                                                            disabled={card["default"]}
                                                            className="block w-full text-left disabled:text-gray_local-400/80 px-4 py-2 text-sm rounded-md text-gray-700 hover:bg-gray_local-400/20"
                                                        >
                                                            {t("define-default-payment-method", "Définir comme moyen de paiement par défaut")}
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                handleDeletePaymentMethod(card["id"]);
                                                                setActiveDropdown(null);
                                                            }}
                                                            disabled={card["default"]}
                                                            className={`block w-full text-left disabled:text-gray_local-400/80 px-4 py-2 text-sm rounded-md text-gray-700 ${card["default"] ? "hover:bg-error/20" : "hover:bg-error"}`}
                                                        >
                                                            {t("delete-payment-method", "Supprimer ce moyen de paiement")}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )))
                            }
                        </div>
                    }
                    
                    {/* DISABLED TEST MODE */}
                    <div className="relative flex justify-center items-center group">
                        <button
                            disabled={userInfo?.plan === "Demo"}
                            onClick={() => handleModal("add-payment-method")}
                            className="text-gray_local-400 hover:text-black/60 disabled:text-slate-400"
                        >
                            + {t("Add-a-payment-method", "Ajouter un moyen de paiement")}
                        </button>

                        {userInfo.plan === "Demo" &&
                            <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 hidden group-hover:flex flex-col items-center">
                                <div className="w-3 h-2 bg-slate-600/80" style={{clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)"}}></div>
                                <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                                    {("Not-available-in-demo", "Indisponible en mode démo")}
                                </span>
                            </div>
                        }
                    </div>

                </div>

                <div className="p-6 mb-5 mx-auto flex flex-col bg-white shadow-default rounded-2xl">
                    <h2 className="text-xl font-semibold mb-6">{t("BILLING-HISTORY", "HISTORIQUE DE FACTURATION")}</h2>

                    
                    {invoicesLoading ?
                        <div className="flex justify-center items-center h-[65vh]">
                            <CustomLoader />
                        </div>
                        :
                        <div className="space-y-4">
                            {
                                (!invoicesList?.invoices || invoicesList.invoices.length === 0) ?
                                <p>{t("No-invoice-yet", "Aucune facture n'a été éditée pour le moment.")}</p>
                                :
                                (invoicesList.invoices.map((invoice, index) => (
                                    <div key={`invoice-${index}`} className="bg-gray_local-400/10 rounded-lg hover:bg-gray_local-400/30">
                                        <a href={invoice.url} target="_blank" rel="noopener noreferrer">
                                            <div className="p-3">
                                                {/* On lg and larger, show two rows */}
                                                <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-4">
                                                    {/* First row: Date and Price */}
                                                    <div className="flex justify-between items-center">
                                                        <p className="text-sm">{new Date(invoice.date * 1000).toLocaleDateString('fr-FR')}</p>
                                                        <p className="text-md font-bold">{(invoice.price / 100).toFixed(2)} €</p>
                                                    </div>

                                                    {/* Second row: Status and Product */}
                                                    <div className="flex justify-between items-center mt-2 lg:mt-0">
                                                        <span className="text-sm bg-green-100 text-green-600 px-2 py-1 rounded">
                                                            {t(invoice.status)}
                                                        </span>
                                                        <p className="text-sm">{invoice.product}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )))
                            }
                        </div>
                    }

                    {/* <button onClick={() => console.log("LOAD MORE INVOICES")} className="text-gray_local-400 hover:text-black/60">▼ Afficher plus</button> */}
                </div>
            </div>

            <Dialog open={open} onClose={()=>handleModal()} className="relative z-[999]">
                <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-[999] w-screen overflow-y-auto bg-black/50">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                        <DialogPanel
                        transition
                        className={`relative transform overflow-hidden rounded-2xl bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in ${modalType === "modify" ? "w-10/12" : "max-sm:10/12 md:w-8/12 lg:w-6/12"} sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >

                        <button
                        className="absolute top-2 right-3 text-gray-400 hover:text-gray-600 focus:outline-none text-2xl"
                        onClick={() => handleModal()}
                        >
                        <span className="sr-only">{t("Close", "Fermer")}</span>
                        &#10005;
                        </button>

                        <div className="">
                            <DialogTitle
                            as="h3"
                            className="text-xl font-semibold leading-6 text-gray-900 max-sm:mr-6"
                            >
                                {modalType === "cancel" ? t("Cancel-my-subscription", "Annuler mon abonnement") : (modalType === "modify" ? t("Change-subscription","Changer d'abonnement") : (modalType === "modify" ? t("confirm-subscription-change", "Confirmer le changement d'abonnement") : t("Add-new-payment-method","Ajouter un nouveau moyen de paiement")))}
                            </DialogTitle>

                            {modalType === "modify" &&
                                <PlansOptions
                                    isPage={false}
                                    selectSubscription={handleNewSubscriptionSelection}
                                />
                            }

                            {modalType === "cancel" &&
                                <CancelPlan
                                    returnToOptions={() => setOpen(false)}
                                    currentSubscription={currentSubscription}
                                    refetchCurrentSub={currentSubRefetch}
                                />
                            }

                            {modalType === "validate-change" &&
                                <SelectPlan
                                    newPlan={newSubscription}
                                    currentSubscription={currentSubscription}
                                    refetchCurrentSub={currentSubRefetch}
                                    returnToOptions={() => setModalType("modify")}
                                />
                            }

                            {modalType === "add-payment-method" &&
                                <AddCard
                                    refetch_card={refetch}
                                />
                            }
                        </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

        </div>
    );
};

export default Subscription;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPanelOpen: false,
  openOutline: false,
  openUrlSlug: false,
  currentZone: {
    zone: null,
    isHeadingZone: null,
  },
};

const panelSlice = createSlice({
  name: 'panelSlice',
  initialState,
  reducers: {
    setEditor: (state, action) => {
      state.editor = action.payload;
    },
    setOpenOutline: (state, action) => {
      state.openOutline = action.payload;
    },
    setOpenUrlSlug: (state, action) => {
      state.openUrlSlug = action.payload;
    },
    setIsPanelOpen: (state, action) => {
      state.isPanelOpen = action.payload;
    },
    setCurrentZone: (state, action) => {
      state.currentZone = { ...action.payload };
    },
  },
});

export const {
  setOpenOutline,
  setOpenUrlSlug,
  setIsPanelOpen,
  setCurrentZone,
} = panelSlice.actions;

export default panelSlice.reducer;
